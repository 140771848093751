import mapboxgl from 'mapbox-gl'
import { nearestPointOnLine } from '@turf/nearest-point-on-line'
import chroma from 'chroma-js'
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import MapGL, { Popup, GeolocateControl, Source, Layer, MapProvider } from 'react-map-gl'
import RouteEditorMenu from './RouteEditorMenu'
import MainMenu from './MainMenu'
import classifyEdges from '../api/classifyEdges'
import localStorageService from '../services/localStorageService'
import DesktopMenu from '../components/DesktopMenu'
import MyRoutes from './MyRoutes'
import {
	routeJourney,
	getPlaceFromAddress,
	getUserPlaces,
	saveUserPlace,
	saveUserRoute,
	getUserRoutes,
	deleteUserRoute,
	getPlace,
} from '../api'
import debounce from 'lodash.debounce'
import { distance } from '@turf/distance'
import { AVAILABLE_CITIES, getCityCode } from '../cities'
import { generateImageURL } from '../utils'
import { MAP_LAYERS } from './mapLayers'
import { MAP_STYLES } from './mapStyles'
import { useSearchParams } from 'react-router-dom'
import { useAppContext } from '../App'
import CitySelector from './CitySelector'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default
const TOKEN = 'pk.eyJ1IjoiZGpyYSIsImEiOiJjamZmZ2RzamYyM2JyMzNwYWc1aThzdmloIn0.tuEuIrtp3DK0ALX2J1clEw'

const generateColors = numColors => {
	var colors = []

	for (var i = 0; i < numColors; i++) {
		var randomColor = chroma.random()
		var contrastRatio = chroma.contrast(randomColor, '#ffffff')

		// Adjust the contrast ratio threshold as needed (4.5 is recommended for text readability)
		while (contrastRatio < 4.5) {
			randomColor = chroma.random()
			contrastRatio = chroma.contrast(randomColor, '#ffffff')
		}

		// Convert the color to hex format and add to the array
		var hexColor = randomColor.hex()
		colors.push(hexColor)
	}

	return colors
}

const generateDarkerColor = color => {
	return chroma(color).darken(0.5).hex()
}

const PopupLayers = forwardRef(function PopupLayers(props, ref) {
	// Render popups and other fast changing data in a separate component so that state changes
	// do not cause a lot of expensive rerendering
	const [edgePopup, setEdgePopup] = React.useState(null)
	const [journeyTrackerPopup, setJourneyTrackerPopup] = useState(null)
	const [journeyTracker, setJourneyTracker] = React.useState({
		type: 'FeatureCollection',
		features: [],
	})
	const journeyTrackerRef = useRef(journeyTracker)

	useEffect(() => {
		journeyTrackerRef.current = journeyTracker
	}, [journeyTracker])

	useImperativeHandle(
		ref,
		() => ({
			setEdgePopup,
			journeyTrackerRef,
			setJourneyTracker,
			setJourneyTrackerPopup,
		}),
		[],
	)

	return (
		<React.Fragment>
			{props.routeMenuOpened && edgePopup && (
				<Popup
					latitude={edgePopup.latitude}
					longitude={edgePopup.longitude}
					closeButton={false}
					closeOnClick={false}
					offset={15}>
					<div style={{ fontSize: 10, color: '#888' }}>ogc_fid {edgePopup.ogc_fid}</div>
					{edgePopup.image_name && (
						<div style={{ marginTop: 12 }}>
							<img src={edgePopup.image_name} />
						</div>
					)}
				</Popup>
			)}
			{props.isRouting && (
				<React.Fragment>
					{journeyTrackerPopup && (
						<Popup
							latitude={journeyTrackerPopup.latitude}
							longitude={journeyTrackerPopup.longitude}
							closeButton={false}
							closeOnClick={false}
							offset={15}
							className='journey-tracker-popup-info'>
							<div style={{ fontSize: 12, color: '#444', fontFamily: 'sans-serif' }}>
								Ziehen, um die Route zu ändern
							</div>
							<div style={{ marginTop: 8, fontSize: 10, color: '#999', fontFamily: 'sans-serif' }}>
								{journeyTrackerPopup.content}
							</div>
						</Popup>
					)}
					<Source id='journey-tracker' type='geojson' data={journeyTracker}>
						<Layer {...MAP_LAYERS.journeyTrackerLayer} />
					</Source>
				</React.Fragment>
			)}
		</React.Fragment>
	)
})

const Map = props => {
	const { isUserLoggedIn } = useAppContext()
	const mapRef = useRef()
	const popupLayersRef = useRef()
	const [geoLocateControlStyle, setGeoLocateStyle] = useState({
		right: 6,
		top: 20,
		position: 'fixed',
	})
	const [myRoutesOpened, setMyRouteOpened] = useState(true)
	const [isJourneyLoading, setIsJourneyLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [routeTypeChooser, setRouteTypeChooser] = React.useState(searchParams.get('placeName') ? 'myRoutes' : 'officialRoutes')
	const [routeConnections, setRouteConnections] = React.useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [myRouteStops, setMyRouteStops] = useState({
		start: {
			type: 'FeatureCollection',
			features: [],
		},
		destination: {
			type: 'FeatureCollection',
			features: [],
		},
		viaStops: {
			type: 'FeatureCollection',
			features: [],
		},
	})
	const searchRef = useRef()
	const startInputFocused = useRef(false)
	const [mySelectedPlace, setMySelectedPlace] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [userPlaces, setUserPlaces] = useState({ type: 'FeatureCollection', features: [] })
	const [search, setSearch] = useState({ text: '' })
	const [searchResults, setSearchResults] = useState({ type: 'FeatureCollection', features: [] })
	const [userRoutes, setUserRoutes] = useState([])
	const [journeyFeatureCollection, setJourneyFeatureCollection] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [mapStyle, setMapStyle] = useState(MAP_STYLES.DEFAULT)
	const [selectedTab, setSelectedTab] = useState('routeList')
	const [journeyTrackerDragging, setJourneyTrackerDragging] = useState({ dragging: false, existingPoint: null })

	const { city } = getCityCode()
	const cityProperties = AVAILABLE_CITIES[city]
	const initialViewState = {
		latitude: cityProperties.latitude,
		longitude: cityProperties.longitude,
		zoom: cityProperties.zoom,
	}

	useEffect(() => {
		if (routeTypeChooser === 'myRoutes') {
			setMapStyle(MAP_STYLES.ROUTING)
			const userId = localStorageService.getUser()?.user_id
			getUserPlaces({ userId }).then(places => {
				setUserPlaces(places)
			})

			getUserRoutes().then(routes => {
				routes.forEach(route => {
					route.outlineColor = generateDarkerColor(route.color)
					route.selected = true
				})

				setUserRoutes(routes)
			})

			if (searchParams.get('placeName') && searchParams.get('placeLonLat')) {
				setSelectedTab('createRoute')
				setMyRouteStops({
					destination: {
						type: 'FeatureCollection',
						features: [
							{
								type: 'Feature',
								geometry: {
									type: 'Point',
									coordinates: searchParams.get('placeLonLat').split(',').map(parseFloat),
								},
								properties: {
									name: searchParams.get('placeName'),
									enum: 'B',
								},
							},
						],
					},
					start: {
						type: 'FeatureCollection',
						features: [],
					},
					viaStops: {
						type: 'FeatureCollection',
						features: [],
					},
				})
			}
		} else {
			setMapStyle(MAP_STYLES.DEFAULT)
		}
	}, [routeTypeChooser])

	const getClassificationColor = classification => {
		let votes = 0
		let votesNumber = 0

		if (classification.safety && classification.attractiveness && classification.conflict) {
			const detailedVoting = (classification.safety + classification.attractiveness + classification.conflict) / 3
			votes += detailedVoting
			votesNumber++
		}

		// only calculate real values
		if (classification.globalVote) {
			votes += classification.globalVote
			votesNumber++
		}

		const value = votes / votesNumber

		if (votesNumber === 0) {
			return '#B3ACBD'
		}

		if (value < 1.5) {
			return '#ec6d6e'
		} else if (value >= 1.5 && value < 2.5) {
			return '#f3b442'
		} else if (value >= 2.5 && value < 3.5) {
			return '#96b63c'
		} else if (value >= 3.5) {
			return '#59864e'
		}
	}

	const handleClassify = classification => {
		classifyEdges({
			type: 'FeatureCollection',
			features: selectedFeatures.features,
			properties: {
				user_id: localStorageService.getUser()?.user_id,
				...classification,
			},
		})
			.then(data => data)
			.then(() => {
				// in user classification view only thing that changed are edges sent to be reclassified.
				const newClassifiedFeaturesIds = []
				const newClassifiedFeatures = selectedFeatures.features.map(feature => {
					feature.properties.color = getClassificationColor(classification)
					newClassifiedFeaturesIds.push(feature.properties.ogc_fid)
					return feature
				})

				// filter old classifications
				const filteredClassifiedFeatures = classifiedFeatures.features.filter(feature => {
					return !newClassifiedFeaturesIds.includes(feature.properties.ogc_fid)
				})

				setClassifiedFeatures({
					type: 'FeatureCollection',
					features: [...filteredClassifiedFeatures, ...newClassifiedFeatures],
				})

				setTimeout(() => {
					setSelectedFeatures({
						type: 'FeatureCollection',
						features: [],
					})
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	// Allow highlighting edges by clicking them in RouteEditorMenu
	const [clickedOgcFid, setClickedOgcFid] = useState(null)
	const [highlightedOgcFid, setHighlightedOgcFid] = useState(null)
	const [highlightedFeatures, setHighlightedFeatures] = useState({ type: 'FeatureCollection', properties: {}, features: [] })
	useEffect(() => {
		let timeoutId
		const feature = highlightedOgcFid && props.data.features.find(feature => feature.properties.ogc_fid === highlightedOgcFid)
		if (feature) {
			// Update highlighted feature and show it
			setHighlightedFeatures({ type: 'FeatureCollection', properties: {}, features: [feature] })
			mapRef.current?.getMap().setPaintProperty('feature-highlighted-layer', 'line-opacity', 1)

			// Hide highlight after 2 seconds
			timeoutId = setTimeout(() => {
				mapRef.current?.getMap().setPaintProperty('feature-highlighted-layer', 'line-opacity', 0)
				setHighlightedOgcFid(null)
			}, 2000)
		}

		// Clear hiding timeout if highlightedOgcFid changes or component unmounts
		return () => {
			timeoutId && clearTimeout(timeoutId)
		}
	}, [highlightedOgcFid])

	const handleClassificationChange = classification => {
		const classificationColor = getClassificationColor(classification)

		const features = selectedFeatures.features.map(feature => {
			return { ...feature, properties: { ...feature.properties, color: classificationColor } }
		})

		setSelectedFeatures({
			type: 'FeatureCollection',
			features,
		})
	}
	const [selectedFeatures, setSelectedFeatures] = useState({ type: 'FeatureCollection', properties: {}, features: [] })
	const [classifiedFeatures, setClassifiedFeatures] = useState({
		type: 'FeatureCollection',
		properties: {},
		features: (() => {
			return props.classifiedData.features.map(f => {
				f.properties.color = getClassificationColor(f.properties)
				return f
			})
		})(),
	})
	const [routeMenuOpened, setRouteMenuOpened] = useState(false)
	const [isRouteEditorRouting, setIsRouteEditorRouting] = useState(false)
	const [isEditingRouteStartPoint, setIsEditingRouteStartPoint] = useState(false)
	const [activeMenu, setActiveMenu] = useState({ title: 'classify' })
	const [veloData, setVeloData] = React.useState({
		type: 'FeatureCollection',
		features: props.data.features.filter(f => f.properties.route_id && f.properties.route_visible === 1),
	})
	const [adminOption, setAdminOption] = React.useState('route_builder')
	const [veloCityData, setVeloCityData] = React.useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [selectedRouteData, setSelectedRouteData] = useState({
		type: 'FeatureCollection',
		features: [],
	})
	const [selectedOverlay, setSelectedOverlay] = useState({
		type: 'FeatureCollection',
		features: [],
	})

	const [routeStartPointFeature, setRouteStartPointFeature] = useState({
		type: 'FeatureCollection',
		features: [],
	})

	const isRouting = (myRoutesOpened && selectedTab === 'createRoute') || (routeMenuOpened && isRouteEditorRouting)

	const removeStart = () => {
		journeyStateCleanup(true)
	}

	const journeyStateCleanup = includingStops => {
		setJourneyFeatureCollection({
			type: 'FeatureCollection',
			features: [],
		})
		setRouteConnections({
			type: 'FeatureCollection',
			features: [],
		})
		popupLayersRef.current?.setJourneyTracker({ type: 'FeatureCollection', features: [] })
		popupLayersRef.current?.setJourneyTrackerPopup(null)
		if (includingStops) {
			setMyRouteStops({
				start: {
					type: 'FeatureCollection',
					features: [],
				},
				destination: {
					type: 'FeatureCollection',
					features: [],
				},
				viaStops: {
					type: 'FeatureCollection',
					features: [],
				},
			})

			setSearchResults({ type: 'FeatureCollection', features: [] })
			setSearch({ text: '', active: false })
		}
	}

	function getCenterFromBounds(bounds) {
		const centerLng = (bounds[0][0] + bounds[1][0]) / 2
		const centerLat = (bounds[0][1] + bounds[1][1]) / 2
		return [centerLng, centerLat]
	}

	function getBoundsFromCenter(center) {
		const zoom = 14
		const [longitude, latitude] = center
		const halfWidth = 360 / Math.pow(2, zoom) / 2
		const halfHeight = 180 / Math.pow(2, zoom) / 2
		const bounds = {
			west: longitude - halfWidth,
			east: longitude + halfWidth,
			south: latitude - halfHeight,
			north: latitude + halfHeight,
		}

		return [
			[bounds.east, bounds.south],
			[bounds.west, bounds.north],
		]
	}

	const imagesPinsFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [feature.properties.pin_lon, feature.properties.pin_lat],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	const imagesPinsOriginFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [feature.properties.exif_lon, feature.properties.exif_lat],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	const pinConnectionsFeatures = React.useMemo(
		() => ({
			type: 'FeatureCollection',
			features:
				props.images &&
				props.images.features.map(feature => {
					return {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: [
								[feature.properties.exif_lon, feature.properties.exif_lat],
								[feature.properties.pin_lon, feature.properties.pin_lat],
							],
						},
						properties: { ...feature.properties },
					}
				}),
		}),
		[props.images],
	)

	useEffect(() => {
		// Force repaint fith setData if selectedRouteData or feature properties change (isDeleted)
		// Otherwise dynamic styling is not triggered
		mapRef.current?.getMap().getSource('selectedRoute')?.setData(selectedRouteData)
	}, [selectedRouteData])

	let networkData = !routeMenuOpened ? veloData : props.data
	if (adminOption === 'images') {
		networkData = { type: 'FeatureCollection', features: [] }
	}

	const handleDeleteWaypoint = index => {
		if (index === 0 && !myRouteStops.destination.features.length) {
			// clean up all stops
			journeyStateCleanup(true)
			return
		}

		if (index === 1 && !myRouteStops.viaStops.features.length) {
			// remove destination
			setMyRouteStops({
				...myRouteStops,
				destination: {
					type: 'FeatureCollection',
					features: [],
				},
			})

			journeyStateCleanup(false)
			return
		}

		if (index === 0) {
			// if there are waypoint, set start as first waypoint and remove first waypoint
			if (myRouteStops.viaStops.features.length) {
				setMyRouteStops({
					...myRouteStops,
					start: {
						type: 'FeatureCollection',
						features: [myRouteStops.viaStops.features[0]].map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: 'A',
									name: feature.properties.name,
								},
							}
						}),
					},
					viaStops: {
						type: 'FeatureCollection',
						features: myRouteStops.viaStops.features.slice(1).map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: i + 1,
									name: feature.properties.name || `Wegpunkt #${i + 1}`,
								},
							}
						}),
					},
				})

				return
			}

			// if there is destination set it as start and remove destination
			if (myRouteStops.destination.features.length) {
				setMyRouteStops({
					...myRouteStops,
					start: {
						type: 'FeatureCollection',
						features: [myRouteStops.destination.features[0]].map((feature, i) => {
							return {
								...feature,
								properties: {
									...feature.properties,
									enum: 'A',
									name: feature.properties.name,
								},
							}
						}),
					},
					destination: {
						type: 'FeatureCollection',
						features: [],
					},
				})

				journeyStateCleanup(false)
				return
			}
		}

		setMyRouteStops({
			...myRouteStops,
			viaStops: {
				type: 'FeatureCollection',
				features: myRouteStops.viaStops.features
					.filter((_, i) => i !== index - 1)
					.map((feature, i) => {
						return {
							...feature,
							properties: {
								...feature.properties,
								enum: i + 1,
								name: feature.properties.name || `Wegpunkt #${i + 1}`,
							},
						}
					}),
			},
		})
	}

	const sortStopFeatures = (startFeatureCollection, destinationFeatureCollection, stopFeatureCollection) => {
		// sort stops by projecting then to start / destination line and sorting by distance
		const startFeature = startFeatureCollection.features[0]
		const destinationFeature = destinationFeatureCollection.features[0]
		const stopFeatures = stopFeatureCollection.features

		const startCoord = startFeature.geometry.coordinates
		const destinationCoord = destinationFeature.geometry.coordinates
		const startDestinationLine = [startCoord, destinationCoord]

		if (stopFeatures.length === 0) {
			return stopFeatures
		}

		const stopsWithDistance = stopFeatures.map(stopFeature => {
			const projectedStop = nearestPointOnLine(
				{
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: startDestinationLine,
					},
				},
				stopFeature,
			)
			return { ...stopFeature, properties: { ...stopFeature.properties, projected: projectedStop.geometry.coordinates } }
		})
		// sort by distance from start
		stopsWithDistance.sort(
			(a, b) => distance(a.properties.projected, startCoord) - distance(b.properties.projected, startCoord),
		)

		stopFeatureCollection.features = stopsWithDistance.map((stop, i) => {
			return {
				...stop,
				properties: {
					...stop.properties,
					enum: i + 1,
					name: stop.properties.name || `Wegpunkt #${i + 1}`,
				},
			}
		})
	}

	const clearMySelectedPlace = () => {
		setMySelectedPlace({
			type: 'FeatureCollection',
			features: [],
		})
	}

	const generateLineStringFromFeatureCollection = featureCollection => {
		const newFeatureCollection = { type: 'FeatureCollection', features: [...featureCollection.features] }
		const coordinates = newFeatureCollection.features.reduce((acc, feature) => {
			return [...acc, ...feature.geometry.coordinates]
		}, [])

		return {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: coordinates,
			},
		}
	}

	const handleSetAsStartPoint = feature => {
		setMyRouteStops({
			...myRouteStops,
			start: {
				type: 'FeatureCollection',
				features: [{ ...feature, properties: { ...feature.properties, enum: 'A' } }],
			},
		})
		clearMySelectedPlace()
	}

	const handleSetAsEndPoint = feature => {
		clearMySelectedPlace()
		// current endpoint should go to via stops and new endpoint should be set
		if (myRouteStops.destination.features.length) {
			setMyRouteStops({
				...myRouteStops,
				viaStops: {
					type: 'FeatureCollection',
					features: [
						...myRouteStops.viaStops.features,
						{
							...myRouteStops.destination.features[0],
							properties: {
								...myRouteStops.destination.features[0].properties,
								enum: myRouteStops.viaStops.features.length,
							},
						},
					],
				},
				destination: {
					type: 'FeatureCollection',
					features: [
						{ ...feature, properties: { ...feature.properties, enum: myRouteStops.viaStops.features.length } },
					],
				},
			})

			return
		}
		// in case there is no destination set
		setMyRouteStops({
			...myRouteStops,
			destination: {
				type: 'FeatureCollection',
				features: [{ ...feature, properties: { ...feature.properties, enum: 'B' } }],
			},
		})
	}

	const handleSetAsViaPoint = feature => {
		setMyRouteStops({
			...myRouteStops,
			viaStops: {
				type: 'FeatureCollection',
				features: [
					...myRouteStops.viaStops.features,
					{ ...feature, properties: { ...feature.properties, enum: myRouteStops.viaStops.features.length + 1 } },
				],
			},
		})
		clearMySelectedPlace()
	}

	const handleJourneyRouting = async (startFeatureCollection, destinationFeatureCollection) => {
		// if there are not start and destination then return
		if (!startFeatureCollection.features.length || !destinationFeatureCollection.features.length) {
			return
		}

		// in case start and destination are the same
		if (
			startFeatureCollection.features[0].geometry.coordinates[0] ===
				destinationFeatureCollection.features[0].geometry.coordinates[0] &&
			startFeatureCollection.features[0].geometry.coordinates[1] ===
				destinationFeatureCollection.features[0].geometry.coordinates[1]
		) {
			return
		}

		const start = startFeatureCollection.features[0]
		const destination = destinationFeatureCollection.features[0]
		setIsJourneyLoading(true)
		sortStopFeatures(startFeatureCollection, destinationFeatureCollection, myRouteStops.viaStops)
		const journey = await routeJourney(
			{
				start: start.geometry.coordinates,
				destination: destination.geometry.coordinates,
				stops: myRouteStops.viaStops.features.map(feature => feature.geometry.coordinates),
			},
			routeMenuOpened && isRouteEditorRouting,
		)
		setIsJourneyLoading(false)

		setJourneyFeatureCollection({
			type: 'FeatureCollection',
			features: journey.features.map(feature => {
				return {
					...feature,
					properties: {
						...feature.properties,
						color: '#3874ff',
					},
				}
			}),
		})

		// TODO: implement snapping to the journey line
		const journeyLine = generateLineStringFromFeatureCollection(journey)
		// update myRouteStops viaStops so that all via stops are on a new journey line
		myRouteStops.viaStops.features.forEach((stopFeature, index) => {
			const projectedStop = nearestPointOnLine(journeyLine, stopFeature)
			stopFeature.geometry.coordinates = projectedStop.geometry.coordinates
		})

		// repaint map with new via stops
		mapRef.current?.getSource('my-routes-stops').setData({
			type: 'FeatureCollection',
			features: [...myRouteStops.viaStops.features],
		})

		// NOTE: here we do not want to snap
		// snap new coords for start and destination (closest to the journey line)
		// myRouteStops.start.features[0].geometry.coordinates = journey.features[0].geometry.coordinates[0]
		// myRouteStops.destination.features[0].geometry.coordinates =
		// 	journey.features[journey.features.length - 1].geometry.coordinates[0]

		// repaint map with new start and destination
		mapRef.current?.getSource('my-routes-routing').setData({
			type: 'FeatureCollection',
			features: [...myRouteStops.start.features, ...myRouteStops.destination.features],
		})

		const lineString = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: generateLineStringFromFeatureCollection(journey).geometry.coordinates,
			},
		}
		// instead we want to draw connections to main points
		setRouteConnections({
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [myRouteStops.start.features[0].geometry.coordinates, lineString.geometry.coordinates[0]],
					},
				},
				{
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [
							myRouteStops.destination.features[0].geometry.coordinates,
							lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1],
						],
					},
				},
			],
		})

		// calculate new bounds
		if (!journeyFeatureCollection.features.length) {
			const bounds = [
				[lineString.geometry.coordinates[0][0], lineString.geometry.coordinates[0][1]],
				[
					lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1][0],
					lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1][1],
				],
			]
			mapRef.current?.getMap().fitBounds(bounds, {
				padding: { top: 150, bottom: 150, left: 500, right: 50 },
			})
		}
	}

	const deboundedJourneyTrackerFreeMove = debounce(evt => {
		if (journeyTrackerDragging.dragging) {
			popupLayersRef.current?.setJourneyTracker({
				type: 'FeatureCollection',
				features: [
					{
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [evt.lngLat.lng, evt.lngLat.lat],
						},
					},
				],
			})
		}
	}, 4)

	const debouncedJourneyTrackerMove = debounce(evt => {
		if (!journeyTrackerDragging.dragging && isRouting) {
			const closestFeature = getClosestFeature(evt)

			if (closestFeature?.layer.id === MAP_LAYERS.journeyLayer.id) {
				// Closest feature is the journey line so we can show the tracker
				popupLayersRef.current?.setJourneyTracker({
					type: 'FeatureCollection',
					features: [closestFeature.closestPoint],
				})

				popupLayersRef.current?.setJourneyTrackerPopup({
					longitude: closestFeature.closestPoint.geometry.coordinates[0],
					latitude: closestFeature.closestPoint.geometry.coordinates[1],
					content: getJourneyTrackerPopupText(closestFeature),
				})
			} else {
				// Clear journey tracker if journey line is not the closest feature
				popupLayersRef.current?.setJourneyTracker({ type: 'FeatureCollection', features: [] })
				popupLayersRef.current?.setJourneyTrackerPopup(null)
			}
		}
	}, 4)

	const getJourneyTrackerPopupText = feature => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div>{feature.properties.name}</div>
				<div>Road {feature.properties.highway}</div>
			</div>
		)
	}

	useEffect(() => {
		handleJourneyRouting(myRouteStops.start, myRouteStops.destination)
	}, [myRouteStops.start, myRouteStops.destination, myRouteStops.viaStops])

	const updateMyRouteStops = async (evt, start, destination, viaStops) => {
		const existingPoint = journeyTrackerDragging.existingPoint
		const place = await getPlace({ lon: evt.lngLat.lng, lat: evt.lngLat.lat })
		if (existingPoint) {
			if (existingPoint.properties && existingPoint.properties.enum === 'A') {
				start = {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [evt.lngLat.lng, evt.lngLat.lat],
							},
							properties: {
								enum: 'A',
								name: place.data.r_node_name || 'Wegpunkt A',
							},
						},
					],
				}
			} else if (existingPoint.properties && existingPoint.properties.enum === 'B') {
				destination = {
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [evt.lngLat.lng, evt.lngLat.lat],
							},
							properties: {
								enum: 'B',
								name: place.data.r_node_name || 'Wegpunkt B',
							},
						},
					],
				}
			} else {
				// if existing point is part of via stops, remove that point from via stops
				// find index of existing point in via stops
				const index = viaStops.features.findIndex(feature => feature.properties.enum === existingPoint.properties.enum)
				const newViaStopFeatures = viaStops.features.filter((_, i) => i !== index)
				viaStops = {
					type: 'FeatureCollection',
					features: [
						...newViaStopFeatures,
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [evt.lngLat.lng, evt.lngLat.lat],
							},
							properties: {
								enum: existingPoint.properties.enum,
								name: place.data.r_node_name || `Wegpunkt #${existingPoint.properties.enum}`,
							},
						},
					],
				}
			}
		} else {
			// if no existing point is selected, add new point to via stops
			viaStops = {
				type: 'FeatureCollection',
				features: [
					...viaStops.features,
					{
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [evt.lngLat.lng, evt.lngLat.lat],
						},
						properties: {
							enum: viaStops.features.length + 1,
							name: place.data.r_node_name || `Wegpunkt #${viaStops.features.length + 1}`,
						},
					},
				],
			}
		}

		sortStopFeatures(start, destination, viaStops)
		setMyRouteStops({
			...myRouteStops,
			start: start,
			destination: destination,
			viaStops,
		})
	}

	useEffect(() => {
		// on user places update we should update search results
		const newSearchResults = {
			type: 'FeatureCollection',
			features: [...userPlaces.features],
		}
		setSearchResults(newSearchResults)
	}, [userPlaces])

	useEffect(() => {
		if (search.text) {
			getPlaceFromAddressThrottled(search)
		}
	}, [search])

	const getPlaceFromAddressThrottled = React.useCallback(
		debounce(value => {
			const { text } = value
			getPlaceFromAddress({
				address: text,
			}).then(data => {
				// add user places to search results
				const upFeatures = userPlaces.features.filter(feature => {
					return feature.properties.name.toLowerCase().startsWith(text.toLowerCase())
				})
				setSearchResults({
					type: 'FeatureCollection',
					features: [...upFeatures, ...data.data.features],
				})
			})
		}, 500),
		[],
	)

	const handleOnSearchChange = value => {
		setSearch({
			text: value,
		})
		if (value.length === 0) {
			setSearchResults({
				type: 'FeatureCollection',
				features: userPlaces.features,
			})
		}
	}

	const handlePlaceClicked = feature => {
		const coordinates = feature.geometry.coordinates
		const selectedFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: coordinates,
			},
			properties: {
				...feature.properties,
				color: feature.properties.idle_id ? '#84b200' : '#383838',
			},
		}
		setMySelectedPlace({
			type: 'FeatureCollection',
			features: [selectedFeature],
		})
		mapRef.current?.getMap().flyTo({ center: { lng: coordinates[0], lat: coordinates[1] } })
	}

	const handleOnSearchFocus = () => {
		// set placeSearchResults to user places
		if (userPlaces.features.length > 0 && !searchResults.features.length) {
			setSearchResults({
				type: 'FeatureCollection',
				features: userPlaces.features,
			})
		}
	}

	const handleOnSavePlace = async place => {
		const newPlace = (
			await saveUserPlace({
				userId: localStorageService.getUser().user_id,
				node: place.node,
			})
		).features[0]

		setSearch({
			text: '',
		})

		// we need to update user place list
		setUserPlaces({
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: newPlace.geometry.coordinates,
					},
					properties: {
						...newPlace.properties,
					},
				},
				...userPlaces.features,
			],
		})
	}

	const calcDistance = coordinates => {
		let dist = 0
		for (let i = 0; i < coordinates.length - 1; i++) {
			dist += distance(coordinates[i], coordinates[i + 1], { units: 'kilometers' })
		}

		// round to 2 decimal places
		return Math.round((dist + Number.EPSILON) * 100) / 100
	}

	const handleSaveRoute = async route => {
		if (journeyFeatureCollection.features.length === 0) {
			return
		}

		const lineStringFeature = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: generateLineStringFromFeatureCollection(journeyFeatureCollection).geometry.coordinates,
			},
			properties: {
				type: 'section',
				edges: journeyFeatureCollection.features.map(feature => feature.properties.ogc_fid),
			},
		}

		const startFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: myRouteStops.start.features[0].geometry.coordinates,
			},
			properties: {
				type: 'start',
				name: myRouteStops.start.features[0].properties?.name,
				idle_id: myRouteStops.start.features[0].properties?.idle_id,
				posmoUserNodeId: myRouteStops.start.features[0].properties?.id,
			},
		}

		const destinationFeature = {
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: myRouteStops.destination.features[0].geometry.coordinates,
			},
			properties: {
				type: 'end',
				name: myRouteStops.destination.features[0].properties?.name,
				idle_id: myRouteStops.destination.features[0].properties?.idle_id,
				posmoUserNodeId: myRouteStops.destination.features[0].properties?.id,
			},
		}

		const routeColor = generateColors(1)[0]
		const newRoute = {
			name: route.name,
			type: route.type,
			color: routeColor,
			geojson: {
				type: 'FeatureCollection',
				features: [startFeature, lineStringFeature, destinationFeature],
			},
		}

		const savedRoute = await saveUserRoute(newRoute)
		savedRoute.color = '#2d2047'
		savedRoute.outlineColor = generateDarkerColor(savedRoute.color)
		savedRoute.selected = true
		setUserRoutes([...userRoutes, savedRoute])
		setSelectedTab('routeList')
		journeyStateCleanup(true)
	}

	const handleUserRouteClicked = (routeId, value) => {
		const newUserRoutes = userRoutes.map(route => {
			if (route.id === routeId) {
				route.selected = value
			}
			return route
		})

		setUserRoutes(newUserRoutes)
	}

	const handleDeleteUserRoute = async routeId => {
		await deleteUserRoute({ routeId })
		const newUserRoutes = userRoutes.filter(route => route.id !== routeId)
		setUserRoutes(newUserRoutes)
	}

	const renderUserRoutesStops = () => {
		const urStops = {
			type: 'FeatureCollection',
			features: [],
		}

		userRoutes
			.filter(route => {
				return route.selected
			})
			.forEach(route => {
				const start = route.geojson.features.find(feature => feature.properties.type === 'start')
				const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
				urStops.features.push({ ...start, properties: { ...start.properties, enum: 'A' } })
				urStops.features.push({ ...destination, properties: { ...destination.properties, enum: 'B' } })
			})

		return urStops
	}

	const renderUserRoutesStopConnections = () => {
		const connections = { type: 'FeatureCollection', features: [] }
		userRoutes
			.filter(route => {
				return route.selected
			})
			.forEach(route => {
				const start = route.geojson.features.find(feature => feature.properties.type === 'start')
				const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
				const lineString = route.geojson.features.find(feature => feature.geometry.type === 'LineString')

				const connectionAtoLineStart = {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [start.geometry.coordinates, lineString.geometry.coordinates[0]],
					},
				}

				const connectionBtoLineEnd = {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [
							destination.geometry.coordinates,
							lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1],
						],
					},
				}

				connections.features.push(connectionAtoLineStart)
				connections.features.push(connectionBtoLineEnd)
			})

		return connections
	}

	const renderUserRoutes = () => {
		return {
			type: 'FeatureCollection',
			features: userRoutes
				.filter(route => {
					return route.selected
				})
				.map(route => {
					const start = route.geojson.features.find(feature => feature.properties.type === 'start')
					const destination = route.geojson.features.find(feature => feature.properties.type === 'end')
					const lineString = route.geojson.features.find(feature => feature.geometry.type === 'LineString')
					route.distance = calcDistance(lineString.geometry.coordinates)
					return {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: lineString.geometry.coordinates,
						},
						properties: {
							name: route.name,
							type: route.type,
							start: start.properties,
							destination: destination.properties,
							color: route.color,
							outlineColor: route.outlineColor,
							id: route.id,
						},
					}
				}),
		}
	}

	const getClosestFeature = evt => {
		// Select features close to the event coordinates
		// https://docs.mapbox.com/mapbox-gl-js/example/queryrenderedfeatures-around-point/
		const coordinates = [evt.lngLat.lng, evt.lngLat.lat]
		const bbox = [
			[evt.point.x - 5, evt.point.y - 5],
			[evt.point.x + 5, evt.point.y + 5],
		]
		const features = mapRef.current
			?.queryRenderedFeatures(bbox, {
				layers: isRouting
					? [MAP_LAYERS.journeyLayer.id, MAP_LAYERS.myRoutesRoutingPoints.id, MAP_LAYERS.myRoutesRoutingStops.id]
					: routeMenuOpened
						? [MAP_LAYERS.networkLayer.id, MAP_LAYERS.imagesPinsLayer.id, MAP_LAYERS.imagesOriginPinsLayer.id]
						: [],
			})
			// Find closest point from the feature so that we can sort features by distance from event coordinates
			.map(feature => {
				let closestPoint
				if (feature.geometry.type === 'Point') {
					// Prefer point geometries if they are within query, so set distance to 0
					closestPoint = { ...feature, properties: { ...feature.properties, dist: 0 } }
				} else if (feature.geometry.type === 'LineString') {
					closestPoint = nearestPointOnLine(feature.geometry, coordinates)
				} else {
					// Could not determine closest point for this geometry type, so use event coordinates
					closestPoint = coordinates
				}
				return {
					...feature,
					closestPoint,
				}
			})

		// Sort features by distance from event coordinates
		features.sort((a, b) => a.closestPoint.dist - b.closestPoint.dist)

		const closestFeature = features[0]
		if (closestFeature) {
			// Convert 'null' property values to null
			// https://github.com/mapbox/mapbox-gl-js/issues/8497
			for (const [propertyKey, propertyValue] of Object.entries(closestFeature.properties)) {
				closestFeature.properties[propertyKey] = propertyValue === 'null' ? null : propertyValue
			}
		}
		return closestFeature
	}

	return (
		<MapProvider>
			{/* <CitySelector /> */}
			{isUserLoggedIn && searchParams.get('showMyRoutes') && (
				<MyRoutes
					startInputFocused={startInputFocused}
					searchRef={searchRef}
					selectedTab={selectedTab}
					search={search}
					searchResults={searchResults}
					myRouteStops={myRouteStops}
					userRoutes={userRoutes}
					journeyFeatureCollection={journeyFeatureCollection}
					setSearch={setSearch}
					onPlaceEditorClose={() => {
						clearMySelectedPlace()
					}}
					onDeleteRoute={routeId => {
						handleDeleteUserRoute(routeId)
					}}
					onDeleteWaypoint={index => {
						handleDeleteWaypoint(index)
					}}
					onUserRouteClicked={(routeId, value) => {
						handleUserRouteClicked(routeId, value)
					}}
					onSetSelectedTab={tab => {
						// journeyStateCleanup(true)
						clearMySelectedPlace()
						setSelectedTab(tab)
					}}
					onSetAsStartPoint={feature => {
						handleSetAsStartPoint(feature)
					}}
					onSetAsEndPoint={feature => {
						handleSetAsEndPoint(feature)
					}}
					onSetAsViaPoint={feature => {
						handleSetAsViaPoint(feature)
					}}
					onSearchFocus={value => {
						handleOnSearchFocus(value)
					}}
					onPlaceClicked={feature => handlePlaceClicked(feature)}
					onSearchChange={value => {
						handleOnSearchChange(value)
					}}
					onSaveRoute={route => {
						handleSaveRoute(route)
					}}
					onSearchClose={() => {
						setSearch({
							text: '',
							idle_id: null,
						})
						setSearchResults({
							type: 'FeatureCollection',
							features: userPlaces.features,
						})
						clearMySelectedPlace()
					}}
					onSavePlace={place => {
						handleOnSavePlace(place)
					}}
					myRoutesOpened={myRoutesOpened}
					routeTypeChooser={routeTypeChooser}
					onSetRouteTypeChooser={choice => {
						setRouteTypeChooser(choice)
						if (choice === 'officialRoutes') {
							journeyStateCleanup(true)
							setMyRouteOpened(false)
						} else {
							setMyRouteOpened(true)
						}
					}}
					removeStart={() => removeStart()}
				/>
			)}
			{isUserLoggedIn && (
				<RouteEditorMenu
					city={props.city}
					setSelectedOverlay={setSelectedOverlay}
					adminOption={adminOption}
					setAdminOption={setAdminOption}
					selectedRouteData={selectedRouteData}
					setSelectedRouteData={setSelectedRouteData}
					updateData={props.updateData}
					veloCityData={veloCityData}
					setVeloCityData={setVeloCityData}
					routeMenuOpened={routeMenuOpened}
					setRouteMenuOpened={setRouteMenuOpened}
					data={props.data}
					setSelectedFeatures={setSelectedFeatures}
					selectedFeatures={selectedFeatures.features}
					isEditingRouteStartPoint={isEditingRouteStartPoint}
					setIsEditingRouteStartPoint={setIsEditingRouteStartPoint}
					routeStartPointFeature={routeStartPointFeature}
					setRouteStartPointFeature={setRouteStartPointFeature}
					clickedOgcFid={clickedOgcFid}
					setClickedOgcFid={setClickedOgcFid}
					setHighlightedOgcFid={setHighlightedOgcFid}
					isRouteEditorRouting={isRouteEditorRouting}
					setIsRouteEditorRouting={setIsRouteEditorRouting}
					journeyFeatureCollection={journeyFeatureCollection}
					myRouteStops={myRouteStops}
					handleDeleteWaypoint={handleDeleteWaypoint}
					journeyStateCleanup={journeyStateCleanup}
					mapStyle={mapStyle}
					setMapStyle={setMapStyle}
				/>
			)}
			{!routeMenuOpened && (
				<MainMenu
					onClassify={handleClassify}
					setGeoLocateStyle={setGeoLocateStyle}
					onClassificationChange={handleClassificationChange}
					active={activeMenu}
					data={selectedFeatures}
				/>
			)}
			<MapGL
				id='map'
				ref={mapRef}
				initialViewState={initialViewState}
				mapboxAccessToken={TOKEN}
				style={{ width: '100vw', height: '100vh' }}
				mapStyle={mapStyle}
				//mapbox://styles/mapbox/satellite-v9
				scrollZoom={true}
				dragPan={!journeyTrackerDragging.dragging}
				onMouseMove={evt => {
					debouncedJourneyTrackerMove(evt)
					deboundedJourneyTrackerFreeMove(evt)

					if (routeMenuOpened && !isRouting) {
						const closestFeature = getClosestFeature(evt)
						if (closestFeature) {
							popupLayersRef.current?.setEdgePopup({
								longitude: evt.lngLat.lng,
								latitude: evt.lngLat.lat,
								ogc_fid: closestFeature.properties.ogc_fid,
								image_name: generateImageURL({ imageName: closestFeature.properties.image_name }, 120),
							})
						} else {
							popupLayersRef.current?.setEdgePopup(null)
						}
					} else {
						popupLayersRef.current?.setEdgePopup(null)
					}
				}}
				onMouseDown={evt => {
					popupLayersRef.current?.setJourneyTrackerPopup(null) // hide info popup
					if (isRouting && journeyFeatureCollection.features.length > 0) {
						// Allow dragging if there is already a routing result
						const closestFeature = getClosestFeature(evt)
						if (closestFeature) {
							if (closestFeature.layer.id === MAP_LAYERS.journeyLayer.id) {
								// Closest feature is the journey line
								setJourneyTrackerDragging({ dragging: true, existingPoint: null })
							} else {
								// Closest feature is a point
								setJourneyTrackerDragging({ dragging: true, existingPoint: closestFeature })
							}
						}
					}
				}}
				onMouseUp={evt => {
					if (journeyTrackerDragging.dragging) {
						setJourneyTrackerDragging({ ...journeyTrackerDragging, dragging: false })

						let start = myRouteStops.start
						let destination = myRouteStops.destination
						let viaStops = myRouteStops.viaStops

						updateMyRouteStops(evt, start, destination, viaStops)
					}
				}}
				onClick={async evt => {
					if (isRouting && myRouteStops.start.features.length === 1 && myRouteStops.destination.features.length === 1) {
						return
					}

					const place = await getPlace({ lon: evt.lngLat.lng, lat: evt.lngLat.lat })

					if (isRouting) {
						if (myRouteStops.start.features.length === 0) {
							setMyRouteStops({
								start: {
									type: 'FeatureCollection',
									features: [
										{
											type: 'Feature',
											geometry: {
												type: 'Point',
												coordinates: [evt.lngLat.lng, evt.lngLat.lat],
											},
											properties: { enum: 'A', name: place.data.r_node_name || 'Wegpunkt A' },
										},
									],
								},
								destination: myRouteStops.destination,
								viaStops: myRouteStops.viaStops,
							})

							return
						}

						if (myRouteStops.destination.features.length === 0) {
							setMyRouteStops({
								start: myRouteStops.start,
								destination: {
									type: 'FeatureCollection',
									features: [
										{
											type: 'Feature',
											geometry: {
												type: 'Point',
												coordinates: [evt.lngLat.lng, evt.lngLat.lat],
											},
											properties: {
												enum: 'B',
												name: place.data.r_node_name || 'Wegpunkt B',
											},
										},
									],
								},
								viaStops: myRouteStops.viaStops,
							})
						}
					}
					if (!routeMenuOpened || isRouting) return

					if (isEditingRouteStartPoint) {
						// If editing route start point, set new point and return immediately
						setRouteStartPointFeature({
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [parseFloat(evt.lngLat.lng.toFixed(7)), parseFloat(evt.lngLat.lat.toFixed(7))],
							},
						})
						setIsEditingRouteStartPoint(false)
						return
					}

					const closestFeature = getClosestFeature(evt)
					// Do not allow clicking expired edges
					if (closestFeature && !closestFeature.properties.expired_at) {
						const clickedOgcFid = closestFeature.properties.ogc_fid
						if (selectedRouteData.features.find(feature => feature.properties.ogc_fid === clickedOgcFid)) {
							// Clicked edge already belongs to the route, highlight it
							setClickedOgcFid(clickedOgcFid)
						} else if (!selectedFeatures.features.find(feature => feature.properties.ogc_fid === clickedOgcFid)) {
							// Get feature from original data, event geometry does not always cover the whole edge for some reason
							const feature = props.data.features.find(feature => feature.properties.ogc_fid === clickedOgcFid)
							setSelectedFeatures({
								type: 'FeatureCollection',
								properties: {},
								features: [
									...selectedFeatures.features,
									{
										...feature,
										properties: { ...feature.properties, color: '#5FABE3', lngLat: evt.lngLat },
									},
								],
							})
						} else {
							// remove feature from the list, unclick
							const filteredFeatures = selectedFeatures.features.filter(feature => {
								feature.properties.color = '#5FABE3'
								return feature.properties.ogc_fid !== clickedOgcFid
							})

							setSelectedFeatures({
								type: 'FeatureCollection',
								properties: {},
								features: [...filteredFeatures],
							})
						}
					}
				}}>
				<GeolocateControl
					style={routeMenuOpened ? { right: 6, top: 20, position: 'fixed' } : geoLocateControlStyle}
					positionOptions={{ enableHighAccuracy: true }}
					trackUserLocation={true}
				/>
				<Source id='overlay' type='geojson' data={selectedOverlay}>
					{routeMenuOpened ? <Layer {...MAP_LAYERS.overlayLayer} /> : ''}
				</Source>
				<Source id='userRoutes' type='geojson' data={renderUserRoutes()}>
					{userRoutes.length && selectedTab !== 'createRoute' ? <Layer {...MAP_LAYERS.userRoutesOutlineLayer} /> : ''}
					{userRoutes.length && selectedTab !== 'createRoute' ? <Layer {...MAP_LAYERS.userRoutesLayer} /> : ''}
				</Source>
				<Source id='my-user-routes-routing-connections' type='geojson' data={renderUserRoutesStopConnections()}>
					{userRoutes.length && selectedTab !== 'createRoute' && (
						<Layer {...MAP_LAYERS.myRoutesUserRoutingConnections} />
					)}
				</Source>
				<Source id='userRoutesStops' type='geojson' data={renderUserRoutesStops()}>
					{userRoutes.length && selectedTab !== 'createRoute' ? <Layer {...MAP_LAYERS.userRoutesStopsLayer} /> : ''}
					{userRoutes.length && selectedTab !== 'createRoute' ? <Layer {...MAP_LAYERS.userRoutesStopsTextLayer} /> : ''}
				</Source>
				<Source id='selectedRoute' type='geojson' data={selectedRouteData}>
					{<Layer {...MAP_LAYERS.selectedRouteLayer} />}
				</Source>
				<Source id='network' type='geojson' data={networkData}>
					{routeTypeChooser === 'myRoutes' ? '' : <Layer {...MAP_LAYERS.networkLayer} beforeId='road-label-simple' />}
				</Source>
				<Source id='classified-features-layer' type='geojson' data={classifiedFeatures}>
					{!(routeMenuOpened || routeTypeChooser === 'myRoutes') && (
						<Layer {...MAP_LAYERS.classifiedFeaturesLayer} beforeId='road-label-simple' />
					)}
				</Source>
				<Source id='feature-selected' type='geojson' data={selectedFeatures}>
					<Layer {...MAP_LAYERS.featureSelectedLayerBorder} />
					<Layer {...MAP_LAYERS.featureSelectedLayer} />
				</Source>
				<Source
					id='pin-connections'
					type='geojson'
					data={
						routeMenuOpened && adminOption === 'images'
							? pinConnectionsFeatures
							: { type: 'FeatureCollection', features: [] }
					}>
					<Layer {...MAP_LAYERS.pinConnectionsLayer} />
				</Source>
				<Source
					id='images-pins'
					type='geojson'
					data={
						routeMenuOpened && adminOption === 'images'
							? imagesPinsFeatures
							: { type: 'FeatureCollection', features: [] }
					}>
					<Layer {...MAP_LAYERS.imagesPinsLayer} />
				</Source>
				<Source
					id='images-pins-origin'
					type='geojson'
					data={
						routeMenuOpened && adminOption === 'images'
							? imagesPinsOriginFeatures
							: { type: 'FeatureCollection', features: [] }
					}>
					<Layer {...MAP_LAYERS.imagesOriginPinsLayer} />
				</Source>
				<Source id='journey' type='geojson' data={journeyFeatureCollection}>
					<Layer {...MAP_LAYERS.journeyLayerOutline} />
					<Layer {...MAP_LAYERS.journeyLayer} />
					{isJourneyLoading && <Layer {...MAP_LAYERS.journeyLoadingLayer} />}
				</Source>
				<Source id='my-routes-stops' type='geojson' data={myRouteStops.viaStops}>
					<Layer {...MAP_LAYERS.myRoutesRoutingStops} />
					<Layer {...MAP_LAYERS.myRoutesRoutingStopsText} />
				</Source>
				<Source id='my-routes-routing-connections' type='geojson' data={routeConnections}>
					<Layer {...MAP_LAYERS.myRoutesRoutingConnections} />
				</Source>
				<Source
					id='my-routes-routing'
					type='geojson'
					data={{
						type: 'FeatureCollection',
						features: [
							...myRouteStops.start.features.map(feature => {
								return {
									...feature,
									properties: {
										...feature.properties,
									},
								}
							}),
							...myRouteStops.destination.features.map(feature => {
								return {
									...feature,
									properties: {
										...feature.properties,
									},
								}
							}),
						],
					}}>
					<Layer {...MAP_LAYERS.myRoutesRoutingPoints} />
					<Layer {...MAP_LAYERS.myRoutesRoutingStopsABText} />
				</Source>
				<Source id='my-selected-place' type='geojson' data={mySelectedPlace}>
					<Layer {...MAP_LAYERS.mySelectedPlaceLayer} />
				</Source>
				<Source id='feature-highlighted' type='geojson' data={highlightedFeatures}>
					{routeMenuOpened && <Layer {...MAP_LAYERS.featureHighlightedLayer} />}
				</Source>
				<Source id='start-point' type='geojson' data={routeStartPointFeature}>
					{routeMenuOpened && !(adminOption === 'edge_status') && <Layer {...MAP_LAYERS.startPointLayer} />}
				</Source>
				<PopupLayers routeMenuOpened={routeMenuOpened} isRouting={isRouting} ref={popupLayersRef} />
			</MapGL>
			<DesktopMenu />
		</MapProvider>
	)
}

export default Map
