const constants = {
	// General
	Name: 'Vorname',
	LastName: 'Nachname',
	Email: 'Email',
	Password: 'Password',
	Register: 'Anmelden',
	ForgotPassword: 'Passwort vergessen?',
	DontHaveAccount: 'Du hast noch keinen Account?',
	CreateAccount: 'Registrieren',
	Abort: 'Abbrechen',
	Problem: 'Probleme?',
	ContactSupport: 'Support kontaktieren',
	TermsOfUse: 'Nutzungsbedingungen',
	PrivacyPolicy: 'Datenschutzbestimmung',
	MultipleEntriesPossible: 'Mehrfachnennungen sind möglich',
	Back: 'Zurück',
	Continue: 'Weiter',
	Complete: 'Abschliessen',
	SaveOnComputer: 'Speichern',
	Route: 'Routen',
	Evaluete: 'Bewerten',
	More: 'Mehr',
	Overview: 'Übersicht',
	Submitting: 'Submitting...',
	Segment: 'Segment',
	Segments: 'Segmente',
	Selected: 'ausgewählt',
	Unsure: 'Unsicher',
	Secure: 'Sicher',
	LotsOfConflicts: 'Viele Konflikte',
	NoConflicts: 'Keine Konflikte',
	Unattractive: 'Unattraktiv',
	Attractive: 'Attraktiv',
	Insufficient: 'Ungenügend',
	Terrific: 'Hervorragend',
	Logout: 'Abmelden',
	Or: 'Oder',
	Loading: 'Loading...',
	Security: 'Sicherheit',
	FreedomFromConflict: 'Konfliktfreiheit',
	Attractiveness: 'Attraktivität',
	Skip: 'Überspringen',
	// Login
	'Login.Title': 'Anmeldung',
	'Login.Description--velobserver': 'Mit VelObserver-Konto anmelden.',
	'Login.Description--posmo': 'Mit Posmo-Konto anmelden.',
	'Login.LoginAsPosmo': 'Oder hast du bereits ein Posmo-Konto?',
	'Login.SwitchToPosmo': 'Mit Posmo anmelden',
	'Login.LoginAsVelobserver': 'Oder hast du bereits ein VelObserver-Konto?',
	'Login.SwitchToVelobserver': 'Mit VelObserver anmelden',
	// Forgot Password
	'ForgotPassword.Title': 'Passwort vergessen',
	'ForgotPassword.Description': 'Bitte gib deine E-Mail-Adresse ein, damit wir dir einen Link zusenden können.',
	'ForgotPassword.RequestLink': 'Link anfragen',
	'ForgotPassword.Footer':
		'Bitte beachte, dass es einige Minuten dauern kann, bis du die E-Mail erhältst. Prüfe auch deinen Spam-Ordner, falls du die E-Mail nicht erhältst.',
	// Sign Up
	'SignUp.Title': 'Registrieren',
	'SignUp.ConfirmPassword': 'Password bestätigen',
	'SignUp.NewsletterSubscribe': 'Newsletter abonnieren',
	'SignUp.IAccept': 'Ich akzeptiere die',
	'SignUp.IAcceptAnd': 'und die',
	'SignUp.CreateAccount': 'Konto erstellen',
	// Reset Password
	'ResetPassword.Title': 'Neues Passwort',
	'ResetPassword.Description': 'Heir kannst du ein neues Passwort setzen.',
	'ResetPassword.ConfirmPassword': 'Password bestätigen',
	'ResetPassword.SavePassword': 'Password speichern',
	'ResetPassword.Footer':
		'Bitte beachte, dass es einige Minuten dauern kann, bis du die E-Mail erhältst. Prüfe auch deinen Spam-Ordner, falls du die E-Mail nicht erhältst.',
	// Survey
	'Survey.Title--1/2': 'Herzlich willkommen',
	'Survey.Title--2/2': 'bei VelObserver!',
	'Survey.StepIntro.Description':
		'Bitte verrate uns ein paar wichtige Dinge über dich. So können wir feststellen, wie repräsentativ unsere Nutzer:innen-Basis zusammengesetzt ist und die Daten korrekt gewichten.',
	'Survey.StepIntro.ListParagraph': 'Wir stellen Fragen zu:',
	'Survey.StepIntro.ListItem': 'Alter, Geschlecht, Wohnort.',
	'Survey.StepIntro.Footer': 'Sämtliche Angaben werde vertraulich behandelt (siehe Datenschutzbestimmungen).',
	'Survey.StepIntro.ForEvaluation': 'Zur Bewertung',
	'Survey.StepIntro.ToTheQuestions': 'Zu den Fragen',
	'Survey.PersonalInfo.Label': 'Geschlecht, Alter, Ort',
	'Survey.PersonalInfo.Gender': 'Geschlecht',
	'Survey.PersonalInfo.Femail': 'weiblich',
	'Survey.PersonalInfo.Male': 'männlich',
	'Survey.PersonalInfo.Diverse': 'divers',
	'Survey.PersonalInfo.Alter': 'Alter',
	'Survey.PersonalInfo.YearPlaceholder': 'Jahrgang (z.B. 1950)',
	'Survey.PersonalInfo.Residence': 'Wohnort',
	'Survey.PersonalInfo.PLZ': 'PLZ',
	'Survey.PersonalInfo.Location': 'Ort',
	'Survey.PersonalInfo.Country': 'Land',
	'Survey.ThankYou.Label': 'Vielen Dank, du bist startklar!',
	'Survey.ThankYou.Description':
		'Du kannst jetzt noch vier fakultative Fragen zu deinem Mobilitätsverhalten beantworten. Das dauert höchstens eine Minute, verbessert die Aussagekraft der VelObserver-Bewertungen aber erheblich.',
	'Survey.ThankYou.Footer': 'Sämtliche Angaben werde vertraulich behandelt (siehe Datenschutzbestimmungen).',
	'Survey.HowOftenDoYouUse.Label': 'Wie oft benützt du folgende Verkehrsmittel?',
	'Survey.HowOftenDoYouUse.OnFoot': 'Zu Fuss',
	'Survey.HowOftenDoYouUse.Bike': 'Velo',
	'Survey.HowOftenDoYouUse.PublicTransport': 'Öffentlicher Verkehr',
	'Survey.HowOftenDoYouUse.OwnCar': 'Eigenes Auto, Motorrad',
	'Survey.HowOftenDoYouUse.Never': 'nie',
	'Survey.HowOftenDoYouUse.Rare': 'selten',
	'Survey.HowOftenDoYouUse.Often': 'oft, mehrmals pro Monat',
	'Survey.HowOftenDoYouUse.Daily': 'täglich, mehrmals pro Woche',
	'Survey.WhichBike.Label': 'Welche Velos benützt du?',
	'Survey.WhichBike.EverydayBike': 'Alltagsvelo, Stadtvelo, Citybike',
	'Survey.WhichBike.E-Bike25': 'E-Bike 25 km/h',
	'Survey.WhichBike.E-Bike45': 'E-Bike 45 km/h',
	'Survey.WhichBike.SportsBike': 'Sportvelo (Rennrad, MTB, Gravel Bike, etc.)',
	'Survey.WhichBike.Travel': 'Reise-oder Tourenvelo',
	'Survey.WhichBike.Cargo': 'Cargobike, Lastenvelo',
	'Survey.WhichBike.Sharing': 'Bikesharing (Publibike o. ä.)',
	'Survey.WhichBike.Other': 'Andere',
	'Survey.WhatPurpose.Label': 'Für welche Zwecke nutzt du dein Velo?',
	'Survey.WhatPurpose.Work': 'Arbeit',
	'Survey.WhatPurpose.Education': 'Ausbildung',
	'Survey.WhatPurpose.Leisure': 'Freizeit, Kultur, Sport',
	'Survey.WhatPurpose.Shopping': 'Einkauf, Gastronomie, Dienstleistungen',
	'Survey.WhatPurpose.Holidays': 'Ferien / Reisen',
	'Survey.WhatPurpose.Other': 'Andere',
	'Survey.AreThereChildren.Label': 'Leben Kinder in deinem Haushalt?',
	'Survey.AreThereChildren.Yes': 'ja',
	'Survey.AreThereChildren.No': 'nein',
	'Survey.AreThereChildren.NoInfo': 'keine Angabe',
	'Survey.Finis.Text--1/3': 'Wir werden dich benachrichtigen, sobald du die ersten',
	'Survey.Finis.Text--2/3': 'Velorouten',
	'Survey.Finis.Text--3/3': 'bewerten kannst.',
	// General rating map
	'GeneralRatingMap.EvaluateTheCycle': 'Veloroutennetz bewerten',
	'GeneralRatingMap.ChooseOneOrMore--1/2': 'Wähle durch Klicken einen oder mehrere',
	'GeneralRatingMap.ChooseOneOrMore--2/2': 'Abschnitte aus, um sie zu bewerten.',
	'GeneralRatingMap.HowDoYouRate': 'Wie bewertest du diese Strecke als Velofahrer:in?',
	'GeneralRatingMap.HowDoYouFeel': 'Fühlst du dich als Velofahrer:in sicher auf dieser Strecke?',
	'GeneralRatingMap.CanYouDrive': 'Kannst du hier ungestört und ohne andere zu stören durchfahren?',
	'GeneralRatingMap.DoYouFind': 'Findest du diese Strecke attraktiv?',
	// Global voting
	'GlobalVoting.Title': 'Bewerten',
	'GlobalVoting.Description': 'Wie möchtest du bewerten?',
	'GlobalVoting.PhotosVoting': 'Mit Fotos',
	'GlobalVoting.MapVoting': 'Auf einer Karte',
	'GlobalVoting.RateInDetail': 'Detailliert Bewerten',
	'GlobalVoting.OverallRating': 'Gesamtbewertung',
	'GlobalVoting.LoadingGeneralRatings': 'Loading general ratings...',
	'GlobalVoting.HelpModal.Subtitle': 'Hilfe',
	'GlobalVoting.HelpModal.Title': 'Wie funktioniert das Bewerten?',
	'GlobalVoting.HelpModal.Heading1': 'Bewertung',
	'GlobalVoting.HelpModal.Paragraph1-1':
		'Wenn du auf der Startseite auf «Jetzt Bewerten» klickst, kommst du auf die «Bewertung». Du kannst dir entweder zufällig ausgewählte Bilder anzeigen lassen («Mit Fotos») oder ein Abschnitt auf unserer Karte auswählen («Auf einer Karte»).',
	'GlobalVoting.HelpModal.Paragraph1-2':
		'Wichtig: Für die Bewertung gibt es kein richtig oder falsch, sondern einzig deine subjektive Wahrnehmung. Fährst du hier gerne Velo? Oder findest du den Abschnitt schwierig oder gar gefährlich?',
	'GlobalVoting.HelpModal.Heading2': 'Detaillierte Bewertung',
	'GlobalVoting.HelpModal.Paragraph2-1':
		'Von der einfachen Bewertung kannst du in die «Detaillierte Bewertung» wechseln. Auch hier bewertest du Bilder oder Streckenabschnitte, doch zusätzlich kannst du bestimmen, welches Kriterium du bewerten möchtest.',
	'GlobalVoting.HelpModal.List2Title': 'Folgende Kriterien können bewertet werden:',
	'GlobalVoting.HelpModal.List2Option1': 'Sicherheit',
	'GlobalVoting.HelpModal.List2Option2': 'Konfliktfreiheit',
	'GlobalVoting.HelpModal.List2Option3': 'Attraktivität',
	'GlobalVoting.HelpModal.Paragraph2-2':
		'Die detaillierte Bewertung ermöglicht es, den Grund der Schwächen eines bestimmten Abschnitts eindeutiger zu identifizieren und die Wirkung der baulichen Massnahmen besser zu verstehen.',
	// Classification voting
	'ClassificationVoting.Title': 'Bewerten',
	'ClassificationVoting.Description': 'Wähle ein Bewertungskriterium:',
	'ClassificationVoting.PhotosVoting': 'Fotos',
	'ClassificationVoting.MapVoting': 'Karte',
	'ClassificationVoting.SecurityDescription': 'Fühlst du dich mit dem Velo in den gezeigten Situationen sicher?',
	'ClassificationVoting.FreedomDescription': 'Kannst du ungestört, und ohne andere zu stören, durchfahren?',
	'ClassificationVoting.AttractivenessDescription': 'Kannst du die Fahrt in der gezeigten Situation geniessen?',
	'ClassificationVoting.SimplifiedAssessment': 'Vereinfacht Bewerten',
	'ClassificationVoting.AlreadyRated': 'Bereits bewertet',
	'ClassificationVoting.LoadingClassificationRatings': 'Loading classification ratings...',
	'ClassificationVoting.HelpModal.Subtitle': 'Hilfe',
	'ClassificationVoting.HelpModal.Title': 'Wie funktioniert das Bewerten?',
	'ClassificationVoting.HelpModal.Heading1': 'Bewertung',
	'ClassificationVoting.HelpModal.Paragraph1-1':
		'Wenn du auf der Startseite auf «Jetzt Bewerten» klickst, kommst du auf die «Bewertung». Du kannst dir entweder zufällig ausgewählte Bilder anzeigen lassen («Mit Fotos») oder ein Abschnitt auf unserer Karte auswählen («Auf einer Karte»).',
	'ClassificationVoting.HelpModal.Paragraph1-2':
		'Wichtig: Für die Bewertung gibt es kein richtig oder falsch, sondern einzig deine subjektive Wahrnehmung. Fährst du hier gerne Velo? Oder findest du den Abschnitt schwierig oder gar gefährlich?',
	'ClassificationVoting.HelpModal.Heading2': 'Detaillierte Bewertung',
	'ClassificationVoting.HelpModal.Paragraph2-1':
		'Von der einfachen Bewertung kannst du in die «Detaillierte Bewertung» wechseln. Auch hier bewertest du Bilder oder Streckenabschnitte, doch zusätzlich kannst du bestimmen, welches Kriterium du bewerten möchtest.',
	'ClassificationVoting.HelpModal.List2Title': 'Folgende Kriterien können bewertet werden:',
	'ClassificationVoting.HelpModal.List2Option1': 'Sicherheit',
	'ClassificationVoting.HelpModal.List2Option2': 'Konfliktfreiheit',
	'ClassificationVoting.HelpModal.List2Option3': 'Attraktivität',
	'ClassificationVoting.HelpModal.Paragraph2-2':
		'Die detaillierte Bewertung ermöglicht es, den Grund der Schwächen eines bestimmten Abschnitts eindeutiger zu identifizieren und die Wirkung der baulichen Massnahmen besser zu verstehen.',
	// Images voting
	'ImagesVoting.Overall': 'Gesamtbewertung',
	'ImagesVoting.YouRatedAll': 'Du hast alle Bilder bewertet. Vielen Dank.',
	'ImagesVoting.DetailedAssessment': 'Detaillierte Bewertung',
	'ImagesVoting.DetailedAssessmentDescription':
		': Situationen nach den Kriterien Sicherheit, Konfliktfreiheit und Attraktivität bewerten.',
	'ImagesVoting.MoreSections': 'Weitere Abschnitte ',
	'ImagesVoting.EvaluateOnMap': 'auf der Karte bewerten.',
	'ImagesVoting.SecureQuestion': 'Fühlst du dich als Velofahrer:in sicher in dieser Situation?',
	'ImagesVoting.ConflictQuestions': 'Kannst du hier ungestört und ohne andere zu stören durchfahren?',
	'ImagesVoting.AttractivenessQuestion': 'Findest du eine Fahrt durch die gezeigte Situation attraktiv?',
	// Menu page
	'MenuPage.Title': 'VelObserver FAQ',
	'MenuPage.Question1': 'Was ist VelObserver?',
	'MenuPage.Answer1--1/3':
		'VelObserver ist Plattform für die Bewertung der Velotauglichkeit von Städten. Gemeinsam mit Dir zeigen wir, wie gut das Veloroutennetz ist und wie es sich verändert. VelObserver wurde im Februar 2021 lanciert und bietet die Bewertung zunächst für das geplante Vorzugsroutennetz der Stadt Zürich an. Damit schaffen wir die Grundlage, um die Umsetzung der Velorouten-Initiative (2020 mit 70,5% Ja angenommen) zu überprüfen. Je aktiver die User bewerten, desto eher werden wir neue Strassen für die Bewertung freischalten.',
	'MenuPage.Answer1--2/3':
		'Das entscheidende Merkmal von VelObserver ist die subjektive Bewertung. Wir wollen wissen, wie Du die Velowege beurteilst. Die Standards, nach denen die Stadt Zürich bisher die Qualität beurteilte, hat mit der Realität auf der Strassen leider sehr wenig zu tun. Wir sind davon überzeugt: Der einzig gültige Massstab sind Velofahrer:innen selbst. Ihnen will VelObserver eine Stimme gehen.',
	'MenuPage.Answer1--3/3':
		'VelObserver ist mit anderen Schweizer Städten im Gespräch, um die Bewertung auch dort anbieten zu können.',
	'MenuPage.Question2': 'Wie funktioniert die Bewertung?',
	'MenuPage.Answer2--1/7':
		'Wir bieten zwei Bewertungsmethoden an, eine einfache Bewertung, die keine Vorkenntnisse benötigt, und eine detaillierte Bewertung, die eine differenzierte Beurteilung ermöglicht, aber etwas aufwändiger ist.',
	'MenuPage.Answer2--2/7': 'Bewertung',
	'MenuPage.Answer2--3/7':
		'Wenn Du auf der Startseite auf «Jetzt Bewerten» klickst, kommst Du auf die einfache Bewertung. Du kannst Dir entweder zufällig ausgewählte Bilder anzeigen lassen oder ein Abschnitt auf unserer Karte auswählen. Wichtig: Für die Bewertung gibt es kein richtig oder falsch, sondern einzig Deine subjektive Wahrnehmung. Fühlst Du Dich sicher? Fährst Du hier gerne Velo? Oder findest Du es schwierig oder gar bedrohlich?',
	'MenuPage.Answer2--4/7': 'Detaillierte Bewertung',
	'MenuPage.Answer2--5/7':
		'Von der einfachen Bewertung kannst Du auf den Expertenmodus wechseln. Hier kannst Du die Abschnitte anhand der VelObserver-Kriterien beurteilen. Diese detailliertere Bewertung ermöglicht es, die Schwächen eines bestimmten Abschnitts eindeutiger zu identifizieren und die Wirkung der baulichen Massnahmen besser zu verstehen.',
	'MenuPage.Answer2--6/7':
		'Unsere Kriterien unterscheiden die wichtigsten Merkmale, die aus einem Veloweg einen Veloweg machen, den jeder zwischen 8 und 80 Jahren benützen kann: Sicherheit, Konfliktfreiheit, Attraktivität. In folgenden Abschnitt erläutern wir die Kriterien. Hier gehts zu einem kurzen',
	'MenuPage.Answer2--7/7': 'Erklärvideo',
	'MenuPage.Question3': 'Was bedeuten die Kriterien?',
	'MenuPage.Answer3--1/7':
		'Wir sind überzeugt, dass ein guter Veloweg drei Kriterien erfüllt: Er ist attraktiv, sicher und frei von Konflikten.',
	'MenuPage.Answer3--2/7':
		'Fühlst Du Dich hier sicher? Nichts hält Menschen so stark von Velofahren ab wie Angst – die Angst zu stürzen, verletzt oder gar getötet zu werden. Den grössten Einfluss auf die subjektive und objektive Sicherheit hat die bauliche Abtrennung von anderen Verkehrsflächen.',
	'MenuPage.Answer3--3/7':
		'Kommen sich hier verschiedene Nutzer:innen des öffentlichen Raums in die Quere? Der Platz in Städten beschränkt. Einige Bedürfnisse lassen sich gut kombinieren, andere nicht – zum Beispiel Verkehr und Spielplätze. Entscheidend für eine Velostadt ist, dass das Velofahren nicht durch andere Nutzungen beeinträchtigt wird und – das ist mindestens so wichtig – dass andere Menschen nicht durch den Veloverkehr benachteiligt oder gefährdet werden.',
	'MenuPage.Answer3--4/7':
		'Ist das ein Ort, an dem Du gerne Velofahren würdest? Das Velo ist in der Stadt das effizienteste, schnellste und zuverlässigste Fortbewegungsmittel. Doch damit es möglichst viele Menschen nutzen können, muss es eben auch angenehm sein. Angenehm wird es, wenn wir etwa zu zweit nebeneinander fahren und uns unterhalten können (wie Autofahrer übrigens) oder wenn die Velowege durch hochwertigen öffentlichen Raum führen.',
	'MenuPage.Answer3--5/7': 'Die VelObserver-Kriterien sind eine Weiterentwicklung der Kriterien des ',
	'MenuPage.Answer3--6/7': 'CROW Design manual for bicycle traffic',
	'MenuPage.Answer3--7/7':
		'. CROW führt zwei weitere Kriterien – Direktheit und Kohäsion. Diese Kriterien beschreiben eine Eigenschaft des Netzes, nicht eines einzelnen Abschnitts, deshalb bieten wir diese Merkmale nicht zur Bewertung an. Wir beabsichtigen jedoch, diese Kriterien in Zunkunt anhand Eurer Daten zu berechnen.',
	'MenuPage.Question4--1/2': 'Was kann ich tun, wenn ich mit einer',
	'MenuPage.Question4--2/2': 'Bewertung nicht einverstanden bin?',
	'MenuPage.Answer4':
		'Jede:r VelObserver:in hat eine eigene Wahrnehmung – deshalb dürfen und sollen sich die Bewertungen auch unterscheiden. Wenn Du glaubst, andere User seien zu grosszügig oder zu streng, dann bewerte am besten selbst. Wenn Du der Meinung bist, die Bewertung eines bestimmten Abschnitts sei fehlerhaft oder manipuliert, dann schicke uns ein Mail.',
	'MenuPage.Question5': 'Was macht Ihr mit meinen Bewertungen?',
	'MenuPage.Answer5--1/4':
		'Deine Bewertungen fliessen in den VelObserver-Index – die visuelle Darstellung sämtlicher Bewertungen auf einer Karte und in Charts. Damit trägst Du dazu bei, die Velotauglichkeit von Zürich sichtbar zu machen.',
	'MenuPage.Answer5--2/4':
		'Wir werden diese Daten regelmässig auswerten und in unserem Blog über die relevanten Ergebnisse berichten.',
	'MenuPage.Answer5--3/4':
		'Wir erstellen Analysen der Bewertungen und stellen sie Verkehrspolitikern und Fachleuten zur Verfügung, die Argumente für die Verbesserung der Velowege benötigen.',
	'MenuPage.Answer5--4/4':
		'Wir liefern der Verwaltung jährlich einen umfassenden Bericht mit allen wichtigen Auswertungen der User-Bewertungen.',
	'MenuPage.Question6--1/2': 'Warum soll ich weitere Fragen',
	'MenuPage.Question6--2/2': 'über mich beantworten?',
	'MenuPage.Answer6':
		'Nach der Registrierung haben wir Dich um die Beantwortung einiger Fragen gebeten. Dieser Fragebogen ist fakultativ, aber es hilft uns enorm, wenn Du ihn ausfüllst. Warum? Entscheidend für eine Velostadt ist, dass sie niemanden von Velofahren ausschliesst. Für Kinder, Senioren oder wenig geübte Personen ist es praktisch unmöglich, in der Stadt Zürich Velozufahren – doch genau auf diese Bevölkerungsgruppen muss sich das künftige Veloroutennetz ausrichten, damit mehr Velo gefahren wird. Damit wir zeigen können, welche Velowege nur für die mutigen und versierten Velofahrer funktionieren und welche für alle, müssen wir möglichst viel über unsere VelObserver:innen wissen.',
	'MenuPage.Question7': 'Wer steht hinter VelObserver?',
	'MenuPage.Answer7--1/2':
		'VelObserver ist ein Projekt der Mobilitätsgenossenschaft Posmo, das im Februar 2021 ins Leben gerufen wurde. Das VelObserver-Team besteht derzeit aus sieben Personen aus den Disziplinen Verkehrsplanung, IT, UX-Design und Kommunikation. Bis zum letzten Spätsommer verrichteten wir sämtliche Arbeit unentgeltlich, seit dem vergangenen Spätsommer ermöglicht uns eine Finanzierung des Prototype-Fund die Entwicklung eines Prototypen.',
	'MenuPage.Answer7--2/2':
		'Posmo und VelObserver sind politisch unabhängig und erhalten weder vom Staat noch von politischen Parteien finanzielle Unterstützung.',
	'MenuPage.Question8--1/2': 'Was kann ich tun, um Euch zu',
	'MenuPage.Question8--2/2': 'unterstützen?',
	'MenuPage.Answer8--1/6':
		'Dass Du diesen Abschnitt liest, freut uns besonders. Hier fünf Vorschläge, wie Du uns helfen kannst:?',
	'MenuPage.Answer8--2/6': 'Bewerte Velowege.',
	'MenuPage.Answer8--3/6': 'Mobilisiere Deine Freunde und Familie, Routen zu bewerten.',
	'MenuPage.Answer8--4/6': 'Werde Mitglied der Mobilitätsgenossenschaft Posmo.',
	'MenuPage.Answer8--5/6': 'Spende uns eine Beitrag zur Weiterentwicklung von VelObserver, z. B. via Twint.',
	'MenuPage.Answer8--6/6':
		'Schicke uns eine Nachricht via Email, Twitter oder Linkedin. Über Lob freuen wir uns – Kritik wird uns helfen, VelObserver zu verbessern.',
}

export default constants
