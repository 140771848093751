import { useState, useEffect } from 'react'
import Input from '../Input'
import Button from '../Button'
import { signup, subscribeEmail } from '../../api'
import { validateEmail } from '../../utils'
import i18n from '../../i18n'
import Strings from '../../strings'
import { useAppContext } from '../../App'

import './style.css'

const SignUp = ({ setView }) => {
	const { updateUser } = useAppContext()
	const [name, setName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPass, setConfirmPass] = useState('')
	const [isAcceepted, setIsAcceepted] = useState(false)
	const [isNewsletter, setIsNewsletter] = useState(false)
	const [errors, setErrors] = useState([])
	const [buttonDisabled, setButtonDisabled] = useState(false)

	useEffect(() => {
		if (name.trim()) {
			const newErr = errors.filter(e => !e.includes(i18n.getLang() === 'en' ? 'first name' : 'Vornamen'))
			setErrors(newErr)
		}
	}, [name])

	useEffect(() => {
		if (lastName.trim()) {
			const newErr = errors.filter(e => !e.includes(i18n.getLang() === 'en' ? 'last name' : 'Nachnamen'))
			setErrors(newErr)
		}
	}, [lastName])

	useEffect(() => {
		if (email.trim()) {
			const newErr = errors.filter(e => !e.includes(i18n.getLang() === 'en' ? 'email' : 'E-Mail'))
			setErrors(newErr)
		}
	}, [email])

	useEffect(() => {
		if (password) {
			const newErr = errors.filter(
				e => !e.includes(i18n.getLang() === 'en' ? 'provide a password' : 'ein Passwort angeben'),
			)
			setErrors(newErr)
		}
	}, [password])

	useEffect(() => {
		if (confirmPass) {
			const newErr = errors.filter(
				e =>
					!e.includes(i18n.getLang() === 'en' ? 'confirm this password' : 'dein Passwort bestätigen') &&
					!e.includes(i18n.getLang() === 'en' ? 'Passwords' : 'Passwörter stimmen nicht überein'),
			)
			setErrors(newErr)
		}
	}, [confirmPass])

	useEffect(() => {
		if (isAcceepted) {
			const newErr = errors.filter(e => !e.includes(i18n.getLang() === 'en' ? 'accept the terms' : 'akzeptieren'))
			setErrors(newErr)
		}
	}, [isAcceepted])

	const onSubmit = async e => {
		e.preventDefault()
		const errors = []
		setErrors([])

		if (!name.trim()) {
			errors.push(i18n.translate(i18n.getMessages().MUST_PROVIDE_A_NAME))
		}

		if (!lastName.trim()) {
			errors.push(i18n.translate(i18n.getMessages().MUST_PROVIDE_A_LAST_NAME))
		}

		if (!email.trim()) {
			errors.push(i18n.translate(i18n.getMessages().MUST_PROVIDE_AN_EMAIL))
		}

		if (email.trim() && !validateEmail(email.trim())) {
			errors.push(i18n.translate(i18n.getMessages().EMAIL_IS_NOT_CORRECT))
		}

		if (!password) {
			errors.push(i18n.translate(i18n.getMessages().MUST_PROVIDE_A_PASSWORD))
		}

		if (!confirmPass) {
			errors.push(i18n.translate(i18n.getMessages().MUST_CONFIRM_PASSWORD))
		}

		if (password && confirmPass && password !== confirmPass) {
			errors.push(i18n.translate(i18n.getMessages().PASSWORDS_DO_NOT_MATCH))
		}

		if (!isAcceepted) {
			errors.push(i18n.translate(i18n.getMessages().MUST_ACCEPT_TOS))
		}

		if (errors.length > 0) {
			setErrors(errors)
			return
		}

		try {
			const dataForSending = {
				name: name.trim(),
				last_name: lastName.trim(),
				email: email.trim(),
				password,
				callback_route: `verify-email?t=`,
			}

			if (isNewsletter) {
				subscribeEmail(email.trim()).catch(err => {
					console.log(err)
				})
			}

			setButtonDisabled(true)
			const req = await signup(dataForSending)
			if (!req.data) {
				setErrors([i18n.translate(i18n.getMessage(req.messageId))])
				setButtonDisabled(false)
			} else {
				updateUser(req.data)
			}
		} catch (err) {
			setErrors([i18n.translate(i18n.getMessages().CONNECTED_TO_THE_INTERNET)])
			setButtonDisabled(false)
		}
	}

	const onAcceptChange = () => {
		setIsAcceepted(!isAcceepted)
	}

	const onNewsletterChange = () => {
		setIsNewsletter(!isNewsletter)
	}

	const onClearAll = () => {
		setName('')
		setLastName('')
		setEmail('')
		setPassword('')
		setConfirmPass('')
		setIsAcceepted(false)
		setErrors([])
		setView('login')
	}

	return (
		<div className='SignupPage'>
			<img className='SignupPage__logo' src='/images/logo.svg' alt='Logo' />
			<h3 className='SignupPage__header'>{Strings.getMessage('SignUp.Title')}</h3>
			<form onSubmit={onSubmit} className='SignupPage__form'>
				<Input value={name} onChange={setName} label={Strings.getMessage('Name')} />
				<Input value={lastName} onChange={setLastName} label={Strings.getMessage('LastName')} />
				<Input
					type='email'
					value={email}
					onChange={setEmail}
					label={Strings.getMessage('Email')}
					placeholder='hallo@example.com'
				/>
				<Input type='password' value={password} onChange={setPassword} label={Strings.getMessage('Password')} />
				<Input
					type='password'
					value={confirmPass}
					onChange={setConfirmPass}
					label={Strings.getMessage('SignUp.ConfirmPassword')}
				/>
				<div className='SignupPage__formAcceptContainer'>
					<input id='signup-accept2' type='checkbox' checked={isNewsletter} onChange={onNewsletterChange} />
					<label className='SignupPage__labelCheckbox' htmlFor='signup-accept2'>
						{Strings.getMessage('SignUp.NewsletterSubscribe')}
					</label>
				</div>
				<div className='SignupPage__formAcceptContainer'>
					<input id='signup-accept1' type='checkbox' checked={isAcceepted} onChange={onAcceptChange} />
					<label className='SignupPage__labelCheckbox' htmlFor='signup-accept1'>
						{Strings.getMessage('SignUp.IAccept')}{' '}
						<a
							className='SignupPage__link'
							href='https://github.com/posmocoop/velobserver/blob/main/nutzungsbedingungen.md'
							target='_blank'
							rel='noreferrer'>
							{Strings.getMessage('TermsOfUse')}
						</a>
						<br />
						{Strings.getMessage('SignUp.IAcceptAnd')}{' '}
						<a
							className='SignupPage__link'
							href='https://github.com/posmocoop/velobserver/blob/main/datenschutz.md#datenschutz'
							target='_blank'
							rel='noreferrer'>
							{Strings.getMessage('PrivacyPolicy')}
						</a>
						.
					</label>
				</div>
				{errors.length > 0 && (
					<div className='SignupPage__errors'>
						{errors.map((e, index) => (
							<div key={`error-${index}`}>{e}</div>
						))}
					</div>
				)}
				<div className='SignupPage__buttons'>
					<Button className='SignupPage__resetButton' type='button' onClick={onClearAll}>
						{Strings.getMessage('Abort')}
					</Button>
					<Button type='submit' apple disabled={buttonDisabled}>
						{Strings.getMessage('SignUp.CreateAccount')}
					</Button>
				</div>
			</form>
		</div>
	)
}

export default SignUp
