export const MAP_LAYERS = {
    overlayLayer: {
        id: 'overlay-layer',
        type: 'line',
        source: 'overlay',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': ['get', 'color'],
            'line-width': 4,
            'line-opacity': 0.4,
        },
    },
    userRoutesLayer: {
        id: 'userRoutesLayer',
        type: 'line',
        source: 'userRoutes',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': ['get', 'color'],
            'line-width': 3,
        },
    },
    userRoutesOutlineLayer: {
        id: 'userRoutesOutlineLayer',
        type: 'line',
        source: 'userRoutes',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': ['get', 'outlineColor'],
            'line-width': 5,
        },
    },
    selectedRouteLayer: {
        id: 'selectedRoute-layer',
        type: 'line',
        source: 'selectedRoute',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            // Add dashes if feature is marked for deletion
            'line-dasharray': [
                'case',
                ['==', ['get', 'isDeleted'], true], ['literal', [3, 3]],
                ['literal', [1, 0]]
            ],
            // Paint expired edges as red, otherwise black
            // https://docs.mapbox.com/style-spec/reference/expressions/
            'line-color': [
                'case',
                ['to-boolean', ['get', 'expired_at']], '#f50057',
                ['==', ['get', 'isDeleted'], true], '#ff565d',
                '#000'
            ],
            // Paint expired edges slightly transparent so user can see new edges underneath
            'line-opacity': [
                'case',
                ['to-boolean', ['get', 'expired_at']], 0.5,
                1
            ],
            'line-width': 4,
        },
    },
    networkLayer: {
        id: 'network-layer',
        type: 'line',
        source: 'network',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': ['get', 'color'],
            'line-width': ['get', 'line_width'],
        },
    },
    journeyLayer: {
        id: 'journey-layer',
        type: 'line',
        source: 'journey',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': ['get', 'color'],
            'line-width': 3,
        },
    },
    journeyLoadingLayer: {
        id: 'journey-loading-layer',
        type: 'line',
        source: 'journey',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': 'orange',
            'line-width': 3,
            'line-opacity': 0.5,
        },
    },
    journeyLayerOutline: {
        id: 'journey-layer-outline',
        type: 'line',
        source: 'journey',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#1a216e',
            'line-width': 5,
        },
    },
	featureHighlightedLayer: {
        id: 'feature-highlighted-layer',
        type: 'line',
        layout: {
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#ffaa00',
            'line-width': 6,
            'line-opacity': 0,
        },
    },
    featureSelectedLayer: {
        id: 'feature-selected-layer',
        type: 'line',
        source: 'feature-selected',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': ['get', 'color'],
            'line-gap-width': 0,
            'line-width': 4,
        },
    },
    featureSelectedLayerBorder: {
        id: 'feature-selected-layer-border',
        type: 'line',
        source: 'feature-selected',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#fff',
            'line-gap-width': 0,
            'line-width': 8,
        },
    },
    classifiedFeaturesLayer: {
        id: 'classified-features-layer',
        type: 'line',
        source: 'classified-features',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': ['get', 'color'],
            'line-width': 4,
        },
    },
    startPointLayer: {
        id: 'start-point-layer',
        type: 'circle',
        source: 'start-point',
        paint: {
            'circle-radius': {
                base: 1.95,
                stops: [
                    [12, 2],
                    [22, 180],
                ],
            },
            'circle-color': 'red',
            'circle-opacity': 1,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#fff',
        },
    },
    journeyTrackerLayer: {
        id: 'journey-tracker-layer',
        type: 'circle',
        source: 'journey',
        paint: {
            'circle-radius': {
                base: 2.2,
                stops: [[12, 3]],
            },
            'circle-color': '#fff',
            'circle-opacity': 1,
            'circle-stroke-width': 5,
            'circle-stroke-color': '#777',
            'circle-stroke-opacity': 0.3,
        },
    },
    myRoutesRoutingConnections: {
        id: 'my-route-routing-connections-layer',
        type: 'line',
        source: 'my-routes-routing-connections',
        layout: {
            'line-join': 'bevel',
            'line-cap': 'butt',
        },
        paint: {
            'line-color': '#2a2a2a',
            'line-width': 4,
            'line-dasharray': [1, 2, 1],
        },
    },
    myRoutesUserRoutingConnections: {
        id: 'my-routes-user-routing-connections-layer',
        type: 'line',
        source: 'my-user-routes-routing-connections',
        layout: {
            'line-join': 'bevel',
            'line-cap': 'butt',
        },
        paint: {
            'line-color': '#2a2a2a',
            'line-width': 4,
            'line-dasharray': [1, 2, 1],
        },
    },
    myRoutesRoutingStops: {
        id: 'my-route-routing-points-stops-layer',
        type: 'circle',
        source: 'my-routes-routing',
        paint: {
            'circle-radius': 8,
            'circle-color': '#383838',
            'circle-opacity': 1,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#2a2a2a',
        },
    },
    userRoutesStopsLayer: {
        id: 'user-routes-stops-layer',
        type: 'circle',
        source: 'my-routes-routing',
        paint: {
            'circle-radius': 8,
            'circle-color': '#383838',
            'circle-opacity': 1,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#2a2a2a',
        },
    },
    userRoutesStopsTextLayer: {
        id: 'user-routes-stops-text-layer',
        type: 'symbol',
        source: 'my-routes-routing',
        layout: {
            'text-field': ['get', 'enum'],
            'text-size': 14,
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-anchor': 'center',
            'text-offset': [0, 0],
            'text-allow-overlap': true,
        },
        paint: {
            'text-color': '#fff',
            'text-halo-width': 2,
        },
    },
    myRoutesRoutingStopsText: {
        id: 'my-route-routing-points-stops-text-layer',
        type: 'symbol',
        source: 'my-routes-stops',
        layout: {
            'text-field': ['get', 'enum'],
            'text-size': 14,
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-anchor': 'center',
            'text-offset': [0, 0],
            'text-allow-overlap': true,
        },
        paint: {
            'text-color': '#fff',
            'text-halo-width': 2,
        },
    },
    myRoutesRoutingStopsABText: {
        id: 'my-route-routing-points-stops-text-ab-layer',
        type: 'symbol',
        source: 'my-routes-routing',
        layout: {
            'text-field': ['get', 'enum'],
            'text-size': 14,
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-anchor': 'center',
            'text-offset': [0, 0],
            'text-allow-overlap': true,
        },
        paint: {
            'text-color': '#fff',
            'text-halo-width': 2,
        },
    },
    mySelectedPlaceLayer: {
        id: 'my-selected-place-layer',
        type: 'circle',
        source: 'my-selected-place',
        paint: {
            'circle-radius': 8,
            'circle-color': ['get', 'color'],
            'circle-opacity': 1,
        },
    },
    myRoutesRoutingPoints: {
        id: 'my-route-routing-points-layer',
        type: 'circle',
        source: 'my-routes-routing',
        paint: {
            'circle-radius': 8,
            'circle-color': '#383838',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#2a2a2a',
        },
    },
    imagesPinsLayer: {
        id: 'images-pins-layer',
        type: 'circle',
        source: 'images-pins',
        layout: {},
        paint: {
            'circle-radius': {
                base: 1.95,
                stops: [
                    [12, 2],
                    [22, 180],
                ],
            },
            'circle-color': '#219F94',
            'circle-opacity': 1,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#fff',
        },
        visibility: 'none',
    },
    pinConnectionsLayer: {
        id: 'pin-connections-layer',
        type: 'line',
        source: 'pin-connections',
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#223b53',
            'line-width': 2,
        },
        visibility: 'none',
    },
    imagesOriginPinsLayer: {
        id: 'images-pins-origin-layer',
        type: 'circle',
        source: 'images-pins-origin',
        layout: {},
        paint: {
            'circle-radius': {
                base: 2.95,
                stops: [
                    [12, 2],
                    [22, 180],
                ],
            },
            'circle-color': '#e55e5e',
            'circle-opacity': 1,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#fff',
        },
        visibility: 'none',
    }
}
