import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Dialog from './Dialog'
import SignUp from './SignUp'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import { useAppContext } from '../App'

const UserDialog = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { isUserDialogOpen, setIsUserDialogOpen } = useAppContext()
	const [view, setView] = useState('login')

	useEffect(() => {
		if (isUserDialogOpen && searchParams.get('t')) {
			// Change to reset password view if there is a token parameter
			setView('resetPassword')
		}
	}, [isUserDialogOpen, searchParams])

	return (
		<Dialog open={isUserDialogOpen} onClose={() => setIsUserDialogOpen(false)}>
			{view === 'login' ? (
				<Login setView={setView} />
			) : view === 'signUp' ? (
				<SignUp setView={setView} />
			) : view === 'forgotPassword' ? (
				<ForgotPassword setView={setView} />
			) : view === 'resetPassword' ? (
				<ResetPassword setView={setView} />
			) : null}
		</Dialog>
	)
}

export default UserDialog
