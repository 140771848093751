import { jwtDecode } from 'jwt-decode'

const localStorageService = (() => {
	const getUser = () => JSON.parse(localStorage.getItem('user'))

	return {
		isAdmin: () => {
			const user = JSON.parse(localStorage.getItem('user'))
			if (
				user &&
				user.roles &&
				(user.roles.defaults.roles.includes('POSMO_TECHNICAL') ||
					user.roles.defaults.roles.includes('POSMO_DATAPROFILER'))
			) {
				return true
			}
			return false
		},
		getUser: () => {
			return JSON.parse(localStorage.getItem('user'))
		},
		setUser: data => {
			let userData = { ...getUser() }
			if (data.jwt) {
				const tokenDecoded = jwtDecode(data.jwt)
				userData = {
					...userData,
					user_id: tokenDecoded.sub,
					roles: tokenDecoded.roles,
					exp: tokenDecoded.exp,
				}
			}
			userData = { ...userData, ...data }
			localStorage.setItem('user', JSON.stringify(userData))
		},
		removeUser: () => {
			localStorage.removeItem('user')
		},
		getUserPretestPanel: () => {
			if (!getUser()) return

			return JSON.parse(localStorage.getItem(`${getUser().user_id}_pretest_panel`))
		},
		setUserPretestPanel: data => {
			if (getUser()) {
				localStorage.setItem(`${getUser().user_id}_pretest_panel`, JSON.stringify(data))
			}
		},
	}
})()

export default localStorageService
