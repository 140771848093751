import { Navigate, Outlet, useLocation } from 'react-router-dom'
import UserDialog from '../components/UserDialog'
import PretestDialog from '../components/PretestDialog'
import { getCityCode } from '../cities'
import App from '../App'

const Root = () => {
	const location = useLocation()
	const { basePath } = getCityCode()

	return location.pathname === '/' ? (
		<Navigate to={basePath} replace />
	) : (
		<App>
			<Outlet />
			<UserDialog />
			<PretestDialog />
		</App>
	)
}

export default Root
