import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemText from '@mui/material/ListItemText'
import FormControlLabel from '@mui/material/FormControlLabel'
import Chip from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import BuildIcon from '@mui/icons-material/Build'
import Alert from '@mui/material/Alert'
import Badge from '@mui/material/Badge'
import LinearProgress from '@mui/material/LinearProgress'
import { createRoute, getEdges, getNetworkRoutes, updateEdgesStatus, updateRouteEdges, updateRouteProps } from '../api'
import { AVAILABLE_CITIES, getCityCode } from '../cities'
import { useMap } from 'react-map-gl'
import mapboxgl from 'mapbox-gl'
import { MAP_STYLES } from './mapStyles'
import classNames from 'classnames'
import { useAppContext } from '../App'

import styles from './RouteEditorMenu.module.css'

const SwitchLayer = ({ mapStyle, setMapStyle }) => {
	const { map } = useMap()

	const handleMapLayerChange = () => {
		if (map) {
			if (mapStyle === MAP_STYLES.DEFAULT) {
				setMapStyle(MAP_STYLES.SATELLITE)
			} else {
				setMapStyle(MAP_STYLES.DEFAULT)
			}
		}
	}

	return (
		<div className={styles.switchLayer} onClick={() => handleMapLayerChange()}>
			<div style={{ position: 'relative' }}>
				<img
					alt='switch satellite / map view'
					src={mapStyle === MAP_STYLES.SATELLITE ? '/assets/map.png' : '/assets/satellite.png'}
					width={80}
					height={60}
				/>
				<div
					style={{
						position: 'absolute',
						bottom: 8,
						left: 8,
						color: mapStyle === MAP_STYLES.SATELLITE ? '#777' : '#fff',
						fontSize: 14,
					}}>
					{mapStyle === MAP_STYLES.SATELLITE ? 'Map' : 'Satellite'}
				</div>
			</div>
		</div>
	)
}

export default function RouteEditorMenu(props) {
	const { isAdmin, isUserLoggedIn } = useAppContext()
	const { map } = useMap()
	const routeMenuOpenedRef = useRef(false)
	const [routeId, setRouteId] = useState('')
	const [statusId, setStatusId] = useState(4)
	const [typeId, setTypeId] = useState(3)
	const [routeEdges, setRouteEdges] = useState([])
	const [edgeStatuses, setEdgeStatuses] = useState([])
	const [edgeStatusValue, setEdgeStatusValue] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const [routes, setRoutes] = useState([])
	const [selectedOverlayItems, setSelectedOverlayItems] = useState([])
	const [originalRouteProperties, setOriginalRouteProperties] = useState({})
	const [routeProperties, setRouteProperties] = useState({})
	const edgeContainerRef = useRef()
	const { city } = getCityCode()
	const { veloplanUrl } = AVAILABLE_CITIES[city]

	const isRouteEdgesEdited = routeEdges.find(edge => edge.isNew || edge.isDeleted)
	const isRoutePropertiesEdited =
		originalRouteProperties?.name !== routeProperties?.name ||
		originalRouteProperties?.route_number !== routeProperties?.route_number ||
		originalRouteProperties?.visible !== routeProperties?.visible ||
		originalRouteProperties?.default !== routeProperties?.default ||
		originalRouteProperties?.start_point?.coordinates.toString() !== routeProperties?.start_point?.coordinates.toString()

	const expiredRoutes = useMemo(() => {
		return [
			...new Set(
				props.data.features
					.filter(feature => !!feature.properties.expired_at)
					.flatMap(feature => feature.properties.route_id),
			),
		]
	}, [props.data])

	useEffect(() => {
		// Clear data on unmount
		return () => clear()
	}, [])

	useEffect(() => {
		// Clear data when route menu status or admin option change
		clear()

		routeMenuOpenedRef.current = props.routeMenuOpened
		if (props.routeMenuOpened && props.adminOption !== 'edge_status') {
			// Select first route if menu is open and not in edge status editor
			// Use setTimeout so that data is cleared in previous cycle and then set again
			setTimeout(() => setRouteId(routes[0]?.id ?? ''))
		}
	}, [props.routeMenuOpened, props.adminOption])

	useEffect(() => {
		if (!props.veloCityData.features.length && isAdmin && veloplanUrl) {
			axios
				.get(veloplanUrl)
				.then(response => {
					const data = response.data
					data.features
						.sort((a, b) => {
							return parseInt(a.properties.nummer) - parseInt(b.properties.nummer)
						})
						.forEach(d => {
							if (!d.properties.color) {
								d.properties.color = 'green'
							}
						})
					props.setVeloCityData(data)
				})
				.catch(err => {
					console.log(err)
				})
		}
	}, [props.veloCityData])

	useEffect(() => {
		if (isUserLoggedIn && isAdmin) {
			setIsLoading(true)
			getEdges()
				.then(data => {
					props.updateData(data)
				})
				.then(() => {
					return getNetworkRoutes()
				})
				.then(data => {
					setRoutes(data)
					if (routeMenuOpenedRef.current) {
						// Set selected route if menu is opened
						setRouteId(data[0]?.id ?? '')
					}
				})
				.catch(err => {
					console.log(err)
					setRoutes([])
				})
				.finally(() => setIsLoading(false))
		}
	}, [isUserLoggedIn, isAdmin])

	useEffect(() => {
		handleRouteChange(routeId)
	}, [routeId])

	const clear = () => {
		setRouteId('')
		props.setSelectedRouteData({ type: 'FeatureCollection', features: [] })
		props.setSelectedFeatures({ type: 'FeatureCollection', features: [] })
		props.setRouteStartPointFeature({ type: 'FeatureCollection', features: [] })
		// Clear routing state
		props.journeyStateCleanup(true)
		props.setIsRouteEditorRouting(false)
	}

	const getStatus = statusId => {
		switch (statusId) {
			case 1:
				return 'Konzept'
			case 2:
				return 'Planung'
			case 3:
				return 'Im Bau'
			case 4:
				return 'Fertig'
			default:
				return 'Fertig'
		}
	}

	const handleRouteChange = (newRouteId, newData, newProperties) => {
		if (newRouteId === '') {
			return
		}

		const data = newData ?? props.data
		// Default to empty properties if not found
		const properties = newProperties ?? (routes.find(route => route.id === newRouteId) || {})
		setOriginalRouteProperties({ ...properties })
		setRouteProperties({ ...properties })

		const routeFeatures = data.features
			.filter(feature => feature.properties.route_id === newRouteId)
			.map(feature => ({ ...feature, properties: { ...feature.properties } }))
		setRouteEdges(routeFeatures.map(feature => feature.properties))

		props.setSelectedRouteData({
			type: 'FeatureCollection',
			// Create copy of properties so that it is a different object than in routeEdges
			features: routeFeatures.map(feature => ({ ...feature, properties: { ...feature.properties } })),
		})
		props.setRouteStartPointFeature(
			properties.start_point
				? { type: 'Feature', geometry: properties.start_point }
				: { type: 'FeatureCollection', features: [] },
		)
		// Clear selected features
		props.setSelectedFeatures({ type: 'FeatureCollection', features: [] })
		// Clear routing state
		props.journeyStateCleanup(true)
		props.setIsRouteEditorRouting(false)

		// Calculate route bounds and focus map there
		// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
		const mapBounds = routeFeatures.reduce((bounds, feature) => {
			feature.geometry.coordinates.forEach(coordinate => bounds.extend(coordinate, coordinate))
			return bounds
		}, new mapboxgl.LngLatBounds())
		if (!mapBounds.isEmpty()) {
			map?.fitBounds(mapBounds, { padding: { top: 150, bottom: 150, left: 500, right: 50 } })
		}
	}

	const handleRouteCreation = async () => {
		const response = await createRoute({ name: 'New route' })
		setRoutes([...routes, response])
		setRouteId(response.id)
	}

	const handleRouteUpdate = async () => {
		setIsLoading(true)

		let newData = props.data
		if (isRoutePropertiesEdited) {
			await updateRouteProps(routeProperties)
			// Update internal route properties
			setRoutes(
				routes.map(route => {
					if (route.id === routeId) {
						return routeProperties
					}
					return route
				}),
			)
		}

		if (isRouteEdgesEdited) {
			const newEdges = routeEdges.filter(edge => !edge.isDeleted)
			newData = await updateRouteEdges(
				routeId,
				newEdges.map(edge => ({
					network_ogc_fid: edge.ogc_fid,
					status: edge.status,
					type: edge.type,
				})),
			)
			props.updateData(newData)
		}

		handleRouteChange(routeId, newData, routeProperties)
		setIsLoading(false)
	}

	const updateDeletedEdges = (fids, forceDeleted) => {
		// Update isDeleted status for internal edges
		setRouteEdges(
			routeEdges.map(routeEdge => {
				if (fids.includes(routeEdge.ogc_fid)) {
					routeEdge.isDeleted = forceDeleted !== undefined ? forceDeleted : !routeEdge.isDeleted
				}
				return { ...routeEdge }
			}),
		)
		// Update isDeleted status for rendered features
		props.setSelectedRouteData({
			type: 'FeatureCollection',
			features: props.selectedRouteData.features.map(feature => {
				if (fids.includes(feature.properties.ogc_fid)) {
					feature.properties.isDeleted = forceDeleted !== undefined ? forceDeleted : !feature.properties.isDeleted
				}
				return feature
			}),
		})
	}

	const applyRoutingResult = () => {
		const currentFids = routeEdges.flatMap(routeEdge => routeEdge.ogc_fid)
		const fids = props.journeyFeatureCollection.features.flatMap(feature => feature.properties.ogc_fid)

		// Reset deleted status for current edges
		updateDeletedEdges(currentFids, false)
		// Update deleted edges
		const deletedFids = currentFids.filter(fid => !fids.includes(fid))
		updateDeletedEdges(deletedFids, true)

		// Update new edges
		const newFids = fids.filter(fid => !currentFids.includes(fid))
		const uniqueFids = []
		const newFeatures = props.data.features
			.filter(feature => {
				const fid = feature.properties.ogc_fid
				// props.data can contain duplicate edges if edges are share between routes, so filter them out
				if (newFids.includes(fid) && !uniqueFids.includes(fid)) {
					uniqueFids.push(fid)
					return true
				}
				return false
			})
			.map(feature => {
				feature.properties.color = '#5FABE3'
				return feature
			})
		// props.selectedRouteData change triggers routeEdges update with useEffect
		props.setSelectedFeatures({ type: 'FeatureCollection', features: newFeatures })

		// Clean up current routing result
		props.journeyStateCleanup(true)
		// Disable routing
		props.setIsRouteEditorRouting(false)
	}

	useEffect(() => {
		setRouteProperties({ ...routeProperties, start_point: props.routeStartPointFeature.geometry })
	}, [props.routeStartPointFeature])

	useEffect(() => {
		if (props.routeMenuOpened && props.selectedFeatures) {
			setEdgeStatuses([
				...props.selectedFeatures.map(feature => {
					return {
						...feature.properties,
						status: statusId,
					}
				}),
			])

			const newRouteEdges = routeEdges.filter(edge => !edge.isNew)
			newRouteEdges.push(
				...props.selectedFeatures.map(feature => ({
					...feature.properties,
					status: statusId,
					type: typeId,
					created_at: Date.now(),
					isNew: true,
				})),
			)
			setRouteEdges(newRouteEdges)
		}
	}, [props.selectedFeatures])

	useEffect(() => {
		let timeoutId
		// Find edge element from edge list
		const edgeElement = edgeContainerRef.current?.querySelector(`tr[data-id='${props.clickedOgcFid}']`)
		if (edgeElement) {
			edgeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
			props.setHighlightedOgcFid(props.clickedOgcFid)

			timeoutId = setTimeout(() => props.setClickedOgcFid(null), 2000)
		}
		return () => {
			timeoutId && clearTimeout(timeoutId)
		}
	}, [props.clickedOgcFid])

	const handleStatusUpdate = async () => {
		setIsLoading(true)

		const newData = await updateEdgesStatus(
			edgeStatuses.map(edge => ({
				ogc_fid: edge.ogc_fid,
				status: parseInt(edgeStatusValue),
			})),
		)
		props.updateData(newData)
		// Clear selection, edgeStatuses is updated via props.selectedFeatures effect
		props.setSelectedFeatures({ type: 'FeatureCollection', features: [] })

		setIsLoading(false)
	}

	const listSelectedEdges = () => (
		<table className={styles.edgeTable} cellSpacing={0} cellPadding={0}>
			<tbody>
				{routeEdges
					.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
					.map(edge => (
						<tr
							key={edge.ogc_fid}
							data-id={edge.ogc_fid}
							className={styles.edgeRow}
							style={{
								color: edge.isNew || edge.isDeleted ? '#777' : '#222',
								background: edge.ogc_fid === props.clickedOgcFid ? '#ffaa00' : 'none',
								textDecoration: edge.isDeleted ? 'line-through' : 'none',
							}}
							onClick={() => {
								props.setHighlightedOgcFid(edge.ogc_fid)
								// Move viewport to the highlighted edge
								const coordinates = props.data.features.find(
									feature => feature.properties.ogc_fid === edge.ogc_fid,
								).geometry.coordinates[0]
								map?.flyTo({ center: { lng: coordinates[0], lat: coordinates[1] }, zoom: 17 })
							}}>
							<td
								style={{
									minWidth: 4,
									backgroundColor:
										edge.isNew || !edge.route_id ? '#1c8131' : edge.isDeleted ? '#ff565d' : '#2D2047',
								}}></td>
							<td>{edge.expired_at && <span title='Expired'>⚠️</span>}</td>
							<td>{edge.ogc_fid}</td>
							<td className={styles.edgeName}>{edge.name}</td>
							<td>
								<span style={{ color: !edge.isNew ? '#222' : '#777' }}>{getStatus(edge.status)}</span>
							</td>
							<td>
								<IconButton
									style={{ padding: 0 }}
									onClick={event => {
										// Do not propagate click to parent div (highlighting)
										event.stopPropagation()
										if (edge.isNew) {
											// Remove selection, routeEdges is updated via props.selectedFeatures effect
											props.setSelectedFeatures({
												type: 'FeatureCollection',
												features: props.selectedFeatures.filter(
													feature => feature.properties.ogc_fid !== edge.ogc_fid,
												),
											})
										} else {
											// Set isDeleted property for committed edge
											updateDeletedEdges([edge.ogc_fid])
										}
									}}>
									{edge.isDeleted ? (
										<RestoreFromTrashIcon style={{ fontSize: 16 }} />
									) : (
										<DeleteIcon style={{ fontSize: 16 }} />
									)}
								</IconButton>
							</td>
						</tr>
					))}
			</tbody>
		</table>
	)

	const listEdgeStatuses = () => (
		<table className={styles.edgeTable} cellSpacing={0} cellPadding={0}>
			<tbody>
				{edgeStatuses
					.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
					.map(edge => (
						<tr
							key={edge.ogc_fid}
							className={styles.edgeRow}
							onClick={() => {
								props.setHighlightedOgcFid(edge.ogc_fid)
								// Move viewport to the highlighted edge
								const coordinates = props.data.features.find(
									feature => feature.properties.ogc_fid === edge.ogc_fid,
								).geometry.coordinates[0]
								map?.flyTo({ center: { lng: coordinates[0], lat: coordinates[1] }, zoom: 17 })
							}}>
							<td style={{ minWidth: 4, backgroundColor: edge.status === 2 ? '#2D2047' : 'gray' }}></td>
							<td>{edge.ogc_fid}</td>
							<td className={styles.edgeName}>{edge.name}</td>
							<td>
								<span style={{ color: !edge.isNew ? '#222' : '#777' }}>{getStatus(edge.status)}</span>
							</td>
							<td>
								<IconButton
									style={{ padding: 0 }}
									onClick={event => {
										// Do not propagate click to parent div (highlighting)
										event.stopPropagation()
										// Remove selection, edgeStatuses is updated via props.selectedFeatures effect
										props.setSelectedFeatures({
											type: 'FeatureCollection',
											features: props.selectedFeatures.filter(
												feature => feature.properties.ogc_fid !== edge.ogc_fid,
											),
										})
									}}>
									<DeleteIcon style={{ fontSize: 16 }} />
								</IconButton>
							</td>
						</tr>
					))}
			</tbody>
		</table>
	)

	const listOverlays = () => {
		return (
			<div className={styles.overlayMenu}>
				<Select
					variant='standard'
					disableUnderline
					multiple
					displayEmpty
					value={selectedOverlayItems}
					onChange={event => {
						if (!event.target.value.includes(undefined)) {
							// Prevent selecting ListSubheader
							setSelectedOverlayItems(event.target.value)
							props.setSelectedOverlay({
								type: 'FeatureCollection',
								features: props.veloCityData.features.filter((feature, index) =>
									event.target.value.includes(index),
								),
							})
						}
					}}
					renderValue={selected => (selected.length ? `${selected.length} selected` : 'Select overlays')}
					classes={{ select: styles.select, icon: styles.selectIcon }}>
					<ListSubheader className={styles.selectSubheader}>
						<Button
							color='primary'
							onClick={event => {
								setSelectedOverlayItems(Array.from(props.veloCityData.features.keys()))
								props.setSelectedOverlay(props.veloCityData)
								// Stop propagation to Select onChange handler
								event.stopPropagation()
							}}>
							Select all
						</Button>
						<Button
							color='error'
							onClick={event => {
								setSelectedOverlayItems([])
								props.setSelectedOverlay({ type: 'FeatureCollection', features: [] })
								// Stop propagation to Select onChange handler
								event.stopPropagation()
							}}>
							Clear all
						</Button>
					</ListSubheader>
					{props.veloCityData.features.map((feature, index) => {
						const label = feature.properties.bezeichnung
							? feature.properties.nummer + ' ' + feature.properties.bezeichnung
							: feature.properties.name
						return (
							<MenuItem key={index} value={index} className={styles.selectMenuItem}>
								<Checkbox color='primary' checked={selectedOverlayItems.indexOf(index) > -1} />
								<ListItemText primary={label} />
							</MenuItem>
						)
					})}
				</Select>
			</div>
		)
	}

	const renderRouteProps = () => {
		return (
			<Fragment>
				<div style={{ display: 'flex', gap: 16 }}>
					<TextField
						variant='outlined'
						size='small'
						label='Route name'
						fullWidth
						value={routeProperties.name ?? ''}
						onChange={event => setRouteProperties({ ...routeProperties, name: event.target.value })}
					/>
					<TextField
						variant='outlined'
						size='small'
						style={{ minWidth: 130 }}
						label='Route number'
						value={routeProperties.route_number ?? ''}
						onChange={event => setRouteProperties({ ...routeProperties, route_number: event.target.value })}
					/>
				</div>
				<div style={{ display: 'flex', gap: 16 }}>
					<FormControlLabel
						control={
							<Checkbox
								color='primary'
								checked={!!routeProperties.visible}
								onChange={event =>
									setRouteProperties({ ...routeProperties, visible: event.target.checked ? 1 : 0 })
								}
							/>
						}
						label='Visible'
					/>
					<FormControlLabel
						control={
							<Checkbox
								color='primary'
								checked={!!routeProperties.default}
								onChange={event =>
									setRouteProperties({ ...routeProperties, default: event.target.checked ? 1 : 0 })
								}
							/>
						}
						label='Default'
					/>
				</div>
			</Fragment>
		)
	}

	const renderLonLat = () => {
		return (
			<div style={{ display: 'flex', gap: 16, marginBottom: 10, alignItems: 'baseline' }}>
				<Button
					variant='outlined'
					color={props.isEditingRouteStartPoint ? 'error' : 'primary'}
					onClick={() => props.setIsEditingRouteStartPoint(!props.isEditingRouteStartPoint)}>
					{props.isEditingRouteStartPoint ? 'Cancel' : 'Edit start point'}
				</Button>
				<TextField
					disabled
					variant='outlined'
					size='small'
					style={{ flex: 1 }}
					label='Start point'
					value={
						routeProperties.start_point ? routeProperties.start_point.coordinates.join(', ') : 'Start point not set'
					}
				/>
			</div>
		)
	}

	return (
		<div className={classNames(styles.root, { [styles.menuClosed]: !props.routeMenuOpened })}>
			{props.routeMenuOpened && props.veloCityData?.features.length > 0 && listOverlays()}
			<div className={styles.menu}>
				<div className={styles.menuContainer}>
					{props.routeMenuOpened && (
						<Select
							variant='standard'
							disableUnderline
							value={props.adminOption}
							onChange={event => props.setAdminOption(event.target.value)}
							classes={{ select: styles.select, icon: styles.selectIcon }}>
							<MenuItem value='route_builder'>Route builder</MenuItem>
							<MenuItem value='edge_status'>Edges status</MenuItem>
							<MenuItem value='images'>Images</MenuItem>
						</Select>
					)}
					{isAdmin && (
						<IconButton
							onClick={() => props.setRouteMenuOpened(!props.routeMenuOpened)}
							style={{
								height: 52,
								width: 52,
								backgroundColor: '#fff',
								borderRadius: '50%',
								boxShadow: '0 1px 6px 2px #e0e0e0',
							}}>
							<BuildIcon style={{ fontSize: 28 }} />
						</IconButton>
					)}
				</div>
			</div>
			{props.routeMenuOpened && (
				<Fragment>
					{props.adminOption !== 'edge_status' && (
						<div className={styles.content}>
							<div className={styles.contentContainer}>
								<div className={styles.routeControls}>
									<Button
										variant='outlined'
										color='primary'
										disabled={isLoading}
										onClick={() => handleRouteCreation()}>
										Create new
									</Button>

									<Select
										variant='outlined'
										size='small'
										style={{ flex: 1, minWidth: 0 }}
										disabled={!routes || routes.length === 0}
										value={routeId}
										onChange={event => setRouteId(event.target.value)}
										displayEmpty
										renderValue={selected => {
											const selectedRoute = routes.find(route => route.id === selected)
											if (selectedRoute) {
												return `${selectedRoute.route_number} ${selectedRoute.name}`
											}
											return 'No routes available'
										}}>
										{routes &&
											routes
												.sort((a, b) => {
													return a.route_number - b.route_number
												})
												.map(route => {
													return (
														<MenuItem
															key={route.id}
															value={route.id}
															style={{ backgroundColor: route.default ? '#f0f0f0' : '#fff' }}>
															<div
																style={{
																	display: 'flex',
																	gap: 16,
																	margin: '0 16px',
																}}>
																<span style={{ display: 'flex' }}>{route.route_number}</span>
																<span style={{ display: 'flex', flex: 1 }}>{route.name}</span>
																{route.visible === 1 && (
																	<span
																		style={{ display: 'flex', fontSize: 10, color: 'green' }}>
																		visible
																	</span>
																)}
																{route.default === 1 && (
																	<span
																		style={{ display: 'flex', fontSize: 10, color: '#bbb' }}>
																		default
																	</span>
																)}
																{expiredRoutes.includes(route.id) && (
																	<span title='Route has expired edges'>⚠️</span>
																)}
															</div>
														</MenuItem>
													)
												})}
									</Select>
								</div>
								{isLoading && (
									<div style={{ margin: 16 }}>
										<LinearProgress />
									</div>
								)}
								{routeId !== '' && (
									<Fragment>
										<Divider />
										<div className={styles.totalEdges}>
											<div>
												Committed edges{' '}
												<span style={{ marginLeft: 4, color: '#444', fontWeight: 'bold' }}>
													{routeEdges.filter(edge => !edge.isNew).length}
												</span>
											</div>
											<div>
												Route length{' '}
												<span style={{ marginLeft: 4, color: '#444', fontWeight: 'bold' }}>
													{routeEdges
														.filter(edge => !edge.isNew)
														.reduce((acc, curr) => (acc += curr.length_km), 0)
														.toFixed(2)}
													km
												</span>
												<span style={{ color: 'green', marginLeft: 4 }}>
													{'+' +
														routeEdges
															.filter(edge => edge.isNew)
															.reduce((acc, curr) => (acc += curr.length_km), 0)
															.toFixed(2)}
												</span>
											</div>
										</div>
										<Divider />
										<div style={{ maxHeight: 350, overflow: 'auto' }} ref={edgeContainerRef}>
											{routeEdges && routeEdges.length > 0 ? (
												listSelectedEdges()
											) : (
												<Alert severity='info' style={{ margin: 8 }}>
													Select edges by clicking them on the map or using the routing tool
												</Alert>
											)}
										</div>
										<Divider />
										<div className={styles.routeInputs}>
											{renderRouteProps()}
											{renderLonLat()}
											<div
												style={{
													justifyContent: 'space-between',
													textAlign: 'center',
													display: 'flex',
													flexDirection: 'row',
												}}>
												<Badge
													anchorOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}}
													badgeContent={
														routeEdges.filter(edge => {
															return edge.isNew || edge.isDeleted
														}).length
													}
													color='primary'>
													<Button
														fullWidth
														variant='outlined'
														color='primary'
														disabled={isLoading || (!isRouteEdgesEdited && !isRoutePropertiesEdited)}
														onClick={() => handleRouteUpdate()}>
														Commit Changes
													</Button>
												</Badge>
												<Button
													variant='outlined'
													color='error'
													disabled={!isRouteEdgesEdited && !isRoutePropertiesEdited}
													onClick={() => handleRouteChange(routeId)}>
													Reset
												</Button>
											</div>
										</div>
									</Fragment>
								)}
							</div>
							{props.adminOption === 'route_builder' && routeId !== '' && (
								<div className={styles.contentContainer} style={{ flexShrink: 0 }}>
									<div className={styles.routingContainer}>
										<FormControlLabel
											control={
												<Checkbox
													color='primary'
													checked={props.isRouteEditorRouting}
													onChange={event => {
														props.setIsRouteEditorRouting(event.target.checked)
														props.journeyStateCleanup(true)
													}}
												/>
											}
											label='Use routing tool'
										/>
										{props.isRouteEditorRouting && (
											<div className={styles.buttonContainer}>
												<Button
													variant='outlined'
													color='primary'
													disabled={!props.journeyFeatureCollection?.features.length}
													onClick={() => applyRoutingResult()}>
													Apply
												</Button>
												<Button
													variant='outlined'
													color='error'
													disabled={!props.journeyFeatureCollection?.features.length}
													onClick={() => props.journeyStateCleanup(true)}>
													Reset
												</Button>
											</div>
										)}
										{props.isRouteEditorRouting && props.myRouteStops?.start.features.length > 0 && (
											<div className={styles.chipContainer}>
												{[
													...props.myRouteStops.start.features,
													...props.myRouteStops.viaStops.features,
													...props.myRouteStops.destination.features,
												].map((stop, index) => (
													<Chip
														key={stop.properties.enum}
														label={stop.properties.enum}
														onDelete={() => props.handleDeleteWaypoint(index)}
													/>
												))}
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					)}
					{props.adminOption === 'edge_status' && (
						<div className={styles.content}>
							<div className={styles.contentContainer}>
								<div className={styles.totalEdges}>
									<div>
										Selected edges{' '}
										<span style={{ marginLeft: 4, color: '#444', fontWeight: 'bold' }}>
											{edgeStatuses.length}
										</span>
									</div>
								</div>
								<Divider />
								{isLoading && (
									<div style={{ margin: 16 }}>
										<LinearProgress />
									</div>
								)}
								<div style={{ maxHeight: 350, overflow: 'auto' }}>
									{edgeStatuses && edgeStatuses.length > 0 ? (
										listEdgeStatuses()
									) : (
										<Alert severity='info' style={{ margin: 8 }}>
											Select edges by clicking them on the map
										</Alert>
									)}
								</div>
								<Divider />
								<div className={styles.edgeControls}>
									<Select
										color='primary'
										variant='outlined'
										size='small'
										value={edgeStatusValue}
										onChange={event => setEdgeStatusValue(event.target.value)}>
										<MenuItem value={1}>{getStatus(1)}</MenuItem>
										<MenuItem value={2}>{getStatus(2)}</MenuItem>
										<MenuItem value={3}>{getStatus(3)}</MenuItem>
										<MenuItem value={4}>{getStatus(4)}</MenuItem>
									</Select>
									<Button
										color='primary'
										variant='outlined'
										size='small'
										disabled={!edgeStatuses.length || isLoading}
										onClick={() => handleStatusUpdate()}>
										Change status
									</Button>
								</div>
							</div>
						</div>
					)}
					<SwitchLayer mapStyle={props.mapStyle} setMapStyle={props.setMapStyle} />
				</Fragment>
			)}
		</div>
	)
}
